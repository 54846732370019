import client from '../api/httpClient';
import * as OPTIONS from '../helpers/options';

class RegulationsController {
  constructor() {
    this.baseUrl = '/search';
  }

  fetchRegulations = async (country, year, level, types, page) => {
    const countrySearch = country?.toLowerCase();
    const params = {
      location: countrySearch === 'world' ? null : countrySearch,
      year: year,
      type:
        types?.length > 0
          ? {
              0: types[0],
              1: types[1],
              2: types[2],
              3: types[3],
            }
          : null,
      region: level === OPTIONS.REGULATION_LEVEL.all.key ? null : level,
      paged: page === 1 ? null : page,
    };
    try {
      const { data } = await client.get(this.baseUrl, { params });
      return data;
    } catch {
      return [];
    }
  };
}

export default new RegulationsController();
