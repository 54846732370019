import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';

import ui from './ui';
import options from './options';

const rootReducer = combineReducers({
  options,
  ui,
});

export default createStore(rootReducer, applyMiddleware(thunk));
