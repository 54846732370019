import styled, { keyframes } from 'styled-components/macro';

export const Container = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  position: relative;
  margin: 10px auto;
`;

const sk_bounce = keyframes`
  0%, 100% { 
    transform: scale(0.0);
  } 
  50% { 
    transform: scale(1.0);
  }
`;

export const Bounce1 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.map.spinner.color};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${sk_bounce} 2s infinite ease-in-out;
`;

export const Bounce2 = styled(Bounce1)`
  animation-delay: -1s;
`;
