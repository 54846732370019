import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { Provider } from 'react-redux';

import store from '../store/store';
import useTheme from '../styles/themes/useTheme';
import { GlobalStyles } from '../styles/common/global';
import { Dashboard } from './dashboard/Dashboard';

import '../styles/common/fonts.css';

const ThemedApp = () => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Dashboard />
    </ThemeProvider>
  );
};

export const App = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/">
          <ThemedApp />
        </Route>
      </Switch>
    </Router>
  </Provider>
);

export default App;
