import { useRef, useEffect } from 'react';

const handleObservation = entries => {
  entries.forEach(entry => {
    const tabSelector = document.querySelector(`[data-target="${entry.target.id}"]`);
    if (tabSelector) {
      if (entry.isIntersecting) {
        document.querySelectorAll('[data-target]').forEach(tab => {
          if (tab.getAttribute('data-target') === entry.target.id) return;
          tab.classList.remove('active');
        });
      }
      tabSelector.classList.toggle('active', entry.isIntersecting);
    }
  });
};

const THRESHOLDS = {
  flowchart: 0.7,
  map: 0.5,
  literature: 0.15,
};

export const useElementObserver = threshold => {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObservation, {
      threshold: THRESHOLDS[threshold] ?? 0,
    });

    const elementObserved = containerRef.current;
    if (elementObserved) observer.observe(elementObserved);

    return () => {
      if (elementObserved) observer.unobserve(elementObserved);
    };
  }, [containerRef, threshold]);

  return containerRef;
};
