import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1.5rem 20px;
  position: relative;
  z-index: 1;

  @media ${device.desktopLarge} {
    padding: 2.5rem 0;
  }

  @media (min-width: 1024px) {
    max-width: 73.75rem;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    max-width: 60rem;
  }
`;

export const ListContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 40% 1fr 1fr;
`;
