import client from '../api/httpClient';

class FiltersController {
  constructor() {
    this.baseUrl = '/filters';
  }

  fetchFilters = async () => {
    try {
      const { data } = await client.get(this.baseUrl);
      return data;
    } catch {
      return [];
    }
  };
}

export default new FiltersController();
