import React, { useCallback } from 'react';

import { ItemContainer, Label, CrossContainer } from './styles';
import { ReactComponent as CrossFill } from 'styles/assets/images/cross-fill.svg';

export const ListItem = ({
  label,
  keyOption,
  column,
  selected,
  handleAdd,
  handleRemove,
  enabled,
}) => {
  const removeFilter = useCallback(
    event => {
      event.stopPropagation();
      handleRemove();
    },
    [handleRemove],
  );

  const addFilter = useCallback(() => {
    if (!selected && enabled) {
      handleAdd();
    }
  }, [selected, handleAdd, enabled]);

  return (
    <ItemContainer
      selected={selected}
      isFirst={column === 0}
      onClick={addFilter}
      disabled={!enabled}
    >
      <Label
        selected={selected}
        isFirst={column === 0}
        enabled={enabled}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      {selected && (
        <CrossContainer onClick={removeFilter}>
          <CrossFill />
        </CrossContainer>
      )}
    </ItemContainer>
  );
};
