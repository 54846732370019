import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  overflow-y: hidden;
  background-image: ${({ background }) => (background?.url ? `url(${background.url})` : '')};
  background-size: cover;
  background-color: ${({ theme }) => theme.filters.background};

  @media ${device.desktopLarge} {
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  min-height: 345px;
  position: relative;

  @media (min-width: 1024px) {
    max-width: 73.75rem;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    max-width: 60rem;
  }
`;
