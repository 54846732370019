import { useSelector } from 'react-redux';

import { THEME as lightTheme } from './light';
import { THEMES } from '../../helpers/ui';

const CUSTOM_THEMES = {
  [THEMES.light]: lightTheme,
};

const useTheme = () => {
  const theme = useSelector((state) => state?.ui?.theme ?? THEMES.light);

  return CUSTOM_THEMES?.[theme] ?? CUSTOM_THEMES[THEMES.light];
};

export default useTheme;
