import styled from 'styled-components/macro';
import device from '../../styles/common/breakpoints';

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
  z-index: 10;

  @media ${device.desktopExtraLarge} {
    left: 0;
    right: initial;
  }
`;

export const ShareButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.filters.share.background};
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  color: ${({ theme }) => theme.filters.share.text};
  padding: 0 0.5rem;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-caption1);
  line-height: 40px;
  letter-spacing: 1.18px;
  cursor: pointer;

  @media ${device.desktopExtraLarge} {
    border-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  svg {
    margin: 0 0.5rem;
    path {
      fill: ${({ theme }) => theme.filters.share.icon};
    }
  }
`;

export const ShareContainer = styled.div`
  position: relative;
`;

export const Message = styled.div`
  font-weight: var(--font-weight-semibold);
  position: absolute;
  pointer-events: none;
  z-index: 100;
  top: calc(100% + 10px);
  left: 0;
  background: ${({ theme }) => theme.filters.headerCard.message.background};
  color: ${({ theme }) => theme.filters.headerCard.message.text};
  width: 150px;
  border-radius: 5px;
  padding: 10px;
  font-size: var(--font-size-caption1);
  line-height: var(--line-height-caption1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.29);

  &::after {
    content: '';
    position: absolute;
    top: -4px;
    left: 15px;
    height: 8px;
    width: 8px;
    background: ${({ theme }) => theme.filters.headerCard.message.background};
    border-radius: 1px;
    transform: rotate(45deg);
  }
`;
