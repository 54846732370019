import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as OPTIONS from 'helpers/options';
import * as optionActions from 'actions/optionActions';

import { Container, ListContainer } from './Taxonomies.styles';
import { Canvas, Filter, Lists } from 'components/';
import { useTaxonomies, useElementObserver } from 'hooks/';

export const Taxonomies = ({ taxonomies, countries, regulations, flags }) => {
  const dispatch = useDispatch();
  const observedRef = useElementObserver('flowchart');
  const sizeRef = useRef();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [regulationsMap, setRegulationsMap] = useState(null);
  const [enabledMap, setEnabledMap] = useState(null);
  const selected = useSelector(
    state => state.options?.[OPTIONS.KEYS.REGULATIONS_SELECTED]?.selected,
  );
  const category = useMemo(() => selected[0][0], [selected]);
  const country = useSelector(
    state => state.options?.[OPTIONS.KEYS.SELECTED_COUNTRY]?.selected,
  )?.toLowerCase();
  const level = useSelector(state => state.options?.[OPTIONS.KEYS.REGULATION_LEVEL]?.selected);
  const [data, loadedData] = useTaxonomies({ taxonomies, regulations, category, level, country });

  const getRegulations = useCallback(
    key => {
      return Object.keys(data)
        .filter(reg => data[reg].types.includes(key.toString()))
        .reduce((obj, key) => [...obj, key], []);
    },
    [data],
  );

  useEffect(() => {
    if (loadedData) {
      const quantities = {};
      Object.keys(taxonomies[category].children).forEach(key => {
        quantities[key] = {};
        Object.keys(taxonomies[category].children[key].children).forEach(keyChild => {
          quantities[key][keyChild] = getRegulations(keyChild);
        });
      });
      setRegulationsMap(quantities);
    }
  }, [category, loadedData, getRegulations, taxonomies]);

  useEffect(() => {
    if (regulationsMap) {
      const columns = {};
      Object.keys(regulationsMap).forEach((key, index) => {
        let items = new Set([]);
        selected[index + 1].forEach(item => {
          items = new Set([...items, ...regulationsMap[key][item]]);
        });
        columns[key] = items;
      });
      let columnSelected = null;
      const global = Object.keys(columns).reduce((obj, key) => {
        if (columns[key].size === 0) {
          return obj;
        } else {
          if (obj.size === 0) {
            columnSelected = key;
            return columns[key];
          } else {
            columnSelected = false;
            return new Set([...columns[key]].filter(x => obj.has(x)));
          }
        }
      }, new Set([]));
      const enable = {};
      Object.keys(regulationsMap).forEach(key => {
        enable[key] = {};
        Object.keys(regulationsMap[key]).forEach(keyChild => {
          enable[key][keyChild] =
            global.size === 0 || columnSelected === key
              ? regulationsMap[key][keyChild].length > 0
              : new Set([...regulationsMap[key][keyChild]].filter(x => global.has(x))).size > 0;
        });
      });
      setEnabledMap(enable);
    }
  }, [selected, taxonomies, category, regulationsMap]);

  const addSelected = useCallback(
    (option, column, selected) => {
      dispatch(optionActions.addSelectedOption(option, column, selected));
      if (column === 0) {
        dispatch(
          optionActions.addOptionArray(OPTIONS.KEYS.REGULATION_CATEGORY, taxonomies[selected].name),
        );
      }
    },
    [dispatch, taxonomies],
  );

  const removeSelected = useCallback(
    (option, column, selected) => {
      dispatch(optionActions.removeSelectedOption(option, column, selected));
      if (column === 0) {
        dispatch(optionActions.clearOptionArray(OPTIONS.KEYS.REGULATION_CATEGORY));
      }
    },
    [dispatch],
  );

  const handleSelectCountry = useCallback(
    selected => {
      dispatch(optionActions.updateSelectedOption(OPTIONS.KEYS.SELECTED_COUNTRY, selected));
    },
    [dispatch],
  );

  // eslint-disable-next-line
  useEffect(() => {
    setSize(size => {
      if (!sizeRef.current) {
        return size;
      } else {
        if (
          size.width !== sizeRef.current.clientWidth ||
          size.height !== sizeRef.current.clientHeight
        ) {
          return {
            width: sizeRef.current.clientWidth,
            height: sizeRef.current.clientHeight,
          };
        } else {
          return size;
        }
      }
    });
  });

  return (
    <>
      <Filter
        taxonomies={taxonomies}
        countries={countries}
        selected={selected}
        flags={flags}
        onCategorySelect={addSelected}
        onCountrySelect={handleSelectCountry}
      />
      <Container id="flowchart" ref={observedRef}>
        <ListContainer ref={sizeRef}>
          <Canvas size={size} selected={selected} taxonomies={taxonomies[selected[0][0]]} />
          {/* <List options={taxonomies} handleAdd={addSelected} handleRemove={removeSelected} /> */}
          {Object.keys(taxonomies[selected[0][0]].children).map(tax => (
            <Lists
              key={taxonomies[selected[0][0]].children[tax].id}
              id={taxonomies[selected[0][0]].children[tax].id}
              title={taxonomies[selected[0][0]].children[tax].name}
              description={taxonomies[selected[0][0]].children[tax].description}
              index={taxonomies[selected[0][0]].children[tax].order}
              options={taxonomies[selected[0][0]].children[tax].children}
              enabledMap={enabledMap}
              handleAdd={addSelected}
              handleRemove={removeSelected}
            />
          ))}
        </ListContainer>
      </Container>
    </>
  );
};
