import React, { useCallback, useState, useRef } from 'react';
import Qs from 'qs';
import copy from 'copy-to-clipboard';

import store from '../../store/store';
import { getChangedOptions } from '../../store/options';
import { Container, ShareButton, ShareContainer, Message } from './FiltersActions.styles';
import { ReactComponent as ShareIcon } from '../../styles/assets/images/share.svg';

const TIMER_DELAY = 3000;

const ShareMessage = ({ visible, children }) => (
  <ShareContainer>
    {visible && <Message>Copied to clipboard!</Message>}
    {children}
  </ShareContainer>
);

const handleShare = () => {
  const state = store.getState().options;
  const values = getChangedOptions(state);
  const url =
    process.env.REACT_APP_URL +
    Qs.stringify(values, { addQueryPrefix: true, encodeValuesOnly: true });
  copy(url);
};

export const FiltersActions = () => {
  const [visible, setVisible] = useState(false);
  const timerRef = useRef(null);

  const onShareClick = useCallback(() => {
    handleShare();
    setVisible(true);
    timerRef.current = setTimeout(() => {
      setVisible(false);
    }, TIMER_DELAY);
  }, []);

  return (
    <Container>
      <ShareMessage visible={visible}>
        <ShareButton onClick={onShareClick}>
          SHARE
          <ShareIcon />
        </ShareButton>
      </ShareMessage>
    </Container>
  );
};
