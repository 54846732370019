import React, { useState, useCallback } from 'react';

import {
  ItemContainer,
  ItemTitle,
  ItemSubtitleContainer,
  ItemSubtitle,
  ItemPreview,
  ItemButton,
  ItemBottomContainer,
  ItemButtonURL,
} from './RegulationsList.styles';

const truncate = str => {
  return str.length > 250 ? str.substr(0, 250) + '&hellip;' : str;
};

const RegulationItem = ({ item, country }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(current => !current), []);

  return (
    <ItemContainer>
      <ItemTitle>{item.title}</ItemTitle>
      <ItemSubtitleContainer>
        {country.key !== 'world' && <ItemSubtitle>{country.label}</ItemSubtitle>}
        {item.passed_date && <ItemSubtitle>Passed {item.passed_date.slice(-4)}</ItemSubtitle>}
      </ItemSubtitleContainer>
      <ItemPreview
        dangerouslySetInnerHTML={{
          __html: open || !item.excerpt ? item.excerpt : truncate(item.excerpt),
        }}
      />
      <ItemBottomContainer open={open}>
        <ItemButton onClick={handleOpen} open={open}>
          {open ? 'Collapse' : 'Expand'}
        </ItemButton>
        {open && !!item.english_url && (
          <ItemButtonURL>
            <a href={item.english_url} target="_blank" rel="noopener noreferrer">
              Read English Regulation
            </a>
          </ItemButtonURL>
        )}
        {open && !!item.native_url && (
          <ItemButtonURL>
            <a href={item.native_url} target="_blank" rel="noopener noreferrer">
              Read Source Regulation
            </a>
          </ItemButtonURL>
        )}
      </ItemBottomContainer>
    </ItemContainer>
  );
};

export const RegulationsList = ({ items, country }) => (
  <>
    {items.map((item, ix) => (
      <RegulationItem key={ix} item={item} country={country} />
    ))}
  </>
);
