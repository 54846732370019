import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as optionActions from '../../../actions/optionActions';
import * as OPTIONS from '../../../helpers/options';
import { Subheader, Taxonomies, FiltersActions } from 'components/';
import { Container, Content } from './Filters.styles';

export const Filters = ({ taxonomies, countries, regulations, flags, background, description }) => {
  const dispatch = useDispatch();
  const regulationSelected = useSelector(
    state => state.options?.[OPTIONS.KEYS.REGULATIONS_SELECTED]?.selected.length > 0,
  );

  useEffect(() => {
    if (!regulationSelected) {
      const taxonomiesValues = typeof taxonomies !== 'undefined' ? Object.values(taxonomies) : [];
      if (taxonomiesValues.length > 0) {
        const aiObj = taxonomiesValues[0];
        if (aiObj.id && aiObj.name) {
          dispatch(optionActions.addSelectedOption(OPTIONS.KEYS.REGULATIONS_SELECTED, 0, aiObj.id));
          dispatch(optionActions.addOptionArray(OPTIONS.KEYS.REGULATION_CATEGORY, aiObj.name));
        }
      }
    }
  }, [taxonomies, regulationSelected, dispatch]);

  return (
    <>
      <Container background={background}>
        <FiltersActions />
        <Content>
          <Subheader description={description} error={typeof taxonomies === 'undefined'} />
        </Content>
      </Container>
      {regulationSelected && (
        <Taxonomies
          taxonomies={taxonomies}
          countries={countries}
          regulations={regulations}
          flags={flags}
        />
      )}
    </>
  );
};
