import React from 'react';
import { useSelector } from 'react-redux';

import * as OPTIONS from 'helpers/options';

import { RegulationsFilter, RegulationsList, Pagination, Spinner } from 'components/';
import {
  RegulationsContainer,
  RegulationsContent,
  NoRegulations,
  ListContainer,
  PaginationContainer,
  Label,
  SpinnerContainer,
} from './Regulations.styles';
import { useElementObserver } from 'hooks/';

export const Regulations = ({ regulations, loading, page, totalPages, onSetPage, countries }) => {
  const observedRef = useElementObserver('literature');
  const articlesFound = regulations?.pagination_data?.total_posts ?? 0;

  const selectedCountry = useSelector(
    state => state.options?.[OPTIONS.KEYS.SELECTED_COUNTRY]?.selected,
  );
  const country = countries.find(country => country.key === selectedCountry);

  return (
    <RegulationsContainer ref={observedRef} id="literature">
      <RegulationsContent>
        <RegulationsFilter years={regulations.years} results={regulations.results} />
        <ListContainer>
          <PaginationContainer>
            <Label enabled={articlesFound > 0}>
              {articlesFound} {articlesFound === 1 ? 'Article' : 'Articles'} found
            </Label>
            <Pagination
              page={page}
              totalPages={totalPages}
              onSetPage={onSetPage}
              enabled={totalPages > 1}
            />
          </PaginationContainer>
          {loading ? (
            <SpinnerContainer mini>
              <Spinner size={70} />
            </SpinnerContainer>
          ) : regulations.results.length === 0 ? (
            <NoRegulations>No articles found. Please try another set of filters.</NoRegulations>
          ) : (
            <RegulationsList items={regulations.results} country={country} />
          )}
          {totalPages > 1 && (
            <PaginationContainer>
              <Label />
              <Pagination page={page} totalPages={totalPages} onSetPage={onSetPage} />
            </PaginationContainer>
          )}
        </ListContainer>
      </RegulationsContent>
    </RegulationsContainer>
  );
};
