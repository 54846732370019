export const WHITE = '#FFFFFF';
export const WHITE_TRANSPARENT = 'rgba(255, 255, 255, 0.9)';
export const BLACK = '#000000';
export const BLACK_2 = '#010101';
export const RED = '#8C1615';
export const RED_2 = '#BC4746';
export const MIDNIGHT = '#24313D';
export const MIDNIGHT2 = '#19232D';
export const CELLO = '#334658';
export const ASTRONAUT_BLUE = '#174662';
export const SHUTTLE_GREY = '#4F6071';
export const TURQUOISE_BLUE = '#62CAEB';
export const TURQUOISE_BLUE_LIGHT = '#69D6FA';
export const SAIL = '#ADD9F4';
export const POLO_BLUE = '#89A3D7';
export const TUNDORA = '#404040';
export const SNOW_WHITE = '#F8F8F8';
export const ALICE_BLUE = '#E7F6FF';
export const PATTENS_BLUE = '#D6E8F2';
export const PATTENS_BLUE_2 = '#D9DCDF';
export const PATTENS_BLUE_3 = '#C4D6E8';
export const NERO = '#1A1A1A';
export const NERO_2 = '#1C1C1C';
export const NOBEL = '#9B9B9B';
export const GLACIER = '#74A4C1';
export const ALABASTER = '#F2F1EA';
export const HEATHER = '#BCBFC2';
