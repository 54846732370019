import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const Container = styled.div`
  width: 100%;
  position: relative;

  .footer-panel__body {
    font: normal 1em / 1.55em 'SourceSansPro-Regular', Helvetica, Arial, sans-serif;
    line-height: 1.6;
  }

  .footer-panel__top__item p,
  .support-us p {
    font: normal 1em / 1.55em 'ss', Arial, sans-serif;
    line-height: 1.6;
  }
  .support-us {
    p {
      margin-bottom: 0.9375rem;
    }
  }

  strong {
    font-weight: var(--font-weight-semibold);
  }

  .footer-global__nav a {
    font-size: 1em;
    font-weight: var(--font-weight-semibold);
    line-height: 1.4;
  }

  .secondUl a {
    font-size: 1em;
    line-height: 1.4;
  }

  .footer-global__copyright span {
    font-size: 1em;
  }

  .social-links {
    margin: 0.9375rem 0;

    li {
      width: 35px;
      height: 35px;
      padding: 0;
      background-color: ${({ theme }) => theme.footer.socialBackground};
      border-radius: 100%;
      margin-right: 7px;
    }

    a:before {
      line-height: 35px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  @media ${device.tablet} {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @media ${device.desktopExtraLarge} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    flex-direction: row;
  }
`;

export const Text = styled.div`
  font-size: var(--font-size-caption2);
  line-height: var(--line-height-caption1);
  letter-spacing: 1px;
  color: ${({ theme }) => theme.footer.text};
  max-width: 170px;
  padding-right: 1rem;
  position: relative;

  @media ${device.tablet} {
    padding-left: 4.5rem;
    padding-right: 0;
    max-width: 250px;
  }

  @media ${device.desktopExtraLarge} {
    padding-left: 0;
    padding-right: 1rem;
    max-width: 170px;
  }

  &::after {
    background: rgba(0, 0, 0, 0.2);
    content: '';
    height: 100%;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;

    @media ${device.tablet} {
      content: none;
    }

    @media ${device.desktopExtraLarge} {
      content: '';
    }
  }
`;
export const Image = styled.div`
  width: 130px;

  @media ${device.tablet} {
    width: 100px;
  }

  @media ${device.desktopExtraLarge} {
    width: 130px;
  }

  & > a > img {
    max-height: 100px;
  }
`;
