import styled from 'styled-components/macro';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 90;
`;

export const Controls = styled.div`
  display: flex;
  text-align: center;
  box-sizing: border-box;
  margin: 1.5rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 46px;
    width: 46px;
    min-width: unset;
    background: transparent;
    border: none;
    cursor: pointer;

    svg {
      fill: ${({ theme }) => theme.map.controls.button.color};
      width: 46px;
      height: 46px;
      transition: fill 0.4s ease;

      &:hover {
        fill: ${({ theme }) => theme.map.controls.button.hover};
      }
    }
  }
`;
