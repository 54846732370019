import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import QuantitiesController from '../controllers/QuantitiesController';
import * as OPTIONS from '../helpers/options';

export const useQuantities = () => {
  const [quantities, setQuantities] = useState(null);
  const [loadedQuantities, setLoadedQuantities] = useState(false);
  const types = useSelector(
    (state) => state.options?.[OPTIONS.KEYS.REGULATIONS_SELECTED]?.selected,
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoadedQuantities(false);
      const result = await QuantitiesController.fetchQuantities(types);
      setQuantities(result);
      setLoadedQuantities(true);
    };
    fetchData();
  }, [types]);

  return [quantities, loadedQuantities];
};
