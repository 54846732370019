import React, { useMemo, useCallback } from 'react';
import { Container, Button } from './Pagination.styles';

export const Pagination = ({ page, totalPages, onSetPage, enabled }) => {
  const pages = useMemo(() => [...Array(totalPages).keys()], [totalPages]);

  const handleFirstPage = useCallback(() => {
    onSetPage(1);
  }, [onSetPage]);

  const handleLastPage = useCallback(() => {
    onSetPage(totalPages);
  }, [onSetPage, totalPages]);

  const handleNextPage = useCallback(() => {
    onSetPage(page + 1);
  }, [onSetPage, page]);

  const handlePrevPage = useCallback(() => {
    onSetPage(page - 1);
  }, [onSetPage, page]);

  const handlePageChange = useCallback(
    number => {
      onSetPage(number);
    },
    [onSetPage],
  );

  return (
    <Container enabled={enabled}>
      {page !== 1 && (
        <>
          <Button onClick={handleFirstPage}>First</Button>
          <Button onClick={handlePrevPage}>Prev</Button>
        </>
      )}
      {pages.map(p => (
        <Button key={`page-${p}`} onClick={() => handlePageChange(p + 1)} selected={p + 1 === page}>
          {p + 1}
        </Button>
      ))}
      {page !== totalPages && (
        <>
          <Button onClick={handleNextPage}>Next</Button>
          <Button onClick={handleLastPage}>Last</Button>
        </>
      )}
    </Container>
  );
};
