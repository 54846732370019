import React, { useState, useEffect } from 'react';

import { Spinner } from 'components/';
import { MapChart } from './Maps';
import { Container, SpinnerContainer } from './Maps.styles';

export const MapsLoader = ({ filters }) => {
  const [loaded, setLoaded] = useState(false);
  const [maps, setMaps] = useState(null);

  useEffect(() => {
    Promise.all([require.context('../../../styles/assets/topojson', false, /\.json$/)]).then(
      jsonList => {
        jsonList = jsonList[0];
        setMaps(
          jsonList.keys().reduce((images, path) => {
            images[path.split('/').pop().split('.json')[0]] = jsonList(path);
            return images;
          }, {}),
        );
        setLoaded(true);
      },
    );
  }, []);

  if (!loaded)
    return (
      <Container>
        <SpinnerContainer>
          <Spinner size={70} />
        </SpinnerContainer>
      </Container>
    );
  return (
    <Container>
      <MapChart maps={maps} filters={filters} />
    </Container>
  );
};
