import styled from 'styled-components/macro';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: ${({ mobileMode }) => (mobileMode ? 'column' : 'row')};
`;

export const ContainerWrapper = styled.div`
  background: ${({ theme }) => theme.mainContent.background};
  flex: 1 1 auto;
  margin: 0px auto;
  width: 100%;
  padding-top: 0px;
  box-sizing: border-box;
`;

export const SpinnerContainer = styled.div`
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
