// This corrections are necessary to properly center the country
// when the map is zoomed in.
export const CORRECTIONS = {
  USA: {
    center: [-98, 38],
    zoom: 5,
  },
  ARG: {
    center: [-65, -40],
    zoom: 4.5,
  },
  RUS: {
    center: [105, 65],
    zoom: 1.7,
  },
  NOR: {
    center: [20, 65],
    zoom: 6.5,
  },
  FRA: {
    center: [3, 46],
    zoom: 14,
  },
};
