import React, { useRef, useLayoutEffect } from 'react';
import { CanvasStyled } from './Canvas.styles';

const RECTANGLE_HEIGHT = 38;
const BACKGROUND_COLOR = '#C4D6E8';
const INITIAL_OFFSET_X = 10;
const INITIAL_OFFSET_Y = 50;
const ROW_HEIGHT = 42;
const SHORTER = 20;
const OFFSET_X = -5;
const CROSS_OFFSET = 55;

const paintSelected = (ctx, column, row, isStart, isEnd, textWidth) => {
  ctx.save();
  ctx.translate(INITIAL_OFFSET_X, INITIAL_OFFSET_Y);
  let height = (row - 1) * ROW_HEIGHT;
  const { width, colWidth } = getColWidth(ctx, column);
  let offsetX = OFFSET_X;
  let startX = width + offsetX;
  let startY = height + 2;
  let rectWidth = colWidth - offsetX - SHORTER;
  if (isStart) {
    startX = width;
    rectWidth = rectWidth - 5;
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.quadraticCurveTo(
      startX - 20,
      startY + RECTANGLE_HEIGHT / 2,
      startX,
      startY + RECTANGLE_HEIGHT,
    );
    ctx.fill();
  }

  if (isEnd) {
    rectWidth = textWidth - offsetX + CROSS_OFFSET;
  }

  ctx.fillRect(startX, startY, rectWidth, RECTANGLE_HEIGHT);

  if (isEnd) {
    ctx.beginPath();
    ctx.moveTo(startX + rectWidth, startY);
    ctx.quadraticCurveTo(
      startX + rectWidth + 20,
      startY + RECTANGLE_HEIGHT / 2,
      startX + rectWidth,
      startY + RECTANGLE_HEIGHT,
    );
    ctx.fill();
  }
  ctx.restore();
};

const paintConnection = (ctx, origRow, destColumn, destRow) => {
  ctx.save();
  ctx.translate(INITIAL_OFFSET_X, INITIAL_OFFSET_Y);
  const originHeight = (origRow - 1) * ROW_HEIGHT + 2;
  const destHeight = (destRow - 1) * ROW_HEIGHT + 2;
  const originRectHeight = RECTANGLE_HEIGHT;
  const { width } = getColWidth(ctx, destColumn);
  const offsetX = OFFSET_X;
  const shorter = SHORTER;
  ctx.translate(width - shorter, originHeight);
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(shorter + offsetX, destHeight - originHeight);
  ctx.lineTo(shorter + offsetX, destHeight - originHeight + RECTANGLE_HEIGHT);
  ctx.lineTo(0, originRectHeight);
  ctx.fill();
  ctx.restore();
};

const getWidth = (ctx, text, fontSize, fontFace) => {
  ctx.font = '600 ' + fontSize + 'px ' + fontFace;
  return ctx.measureText(text).width;
};

const getColWidth = (ctx, column) => {
  const canvasWidth = ctx.canvas.width;

  // Define these values same as ListContainer component
  const colDivisor = column === 1 ? 0.4 : 0.3;

  const selectedWidth = {
    0: 0,
    1: canvasWidth * 0.4,
    2: canvasWidth * (1 - colDivisor),
  };

  return {
    width: selectedWidth[column - 1],
    colWidth: canvasWidth * colDivisor,
  };
};

const draw = (ctx, selected, taxonomies) => {
  selected.forEach((el, column) => {
    if (column > 0) {
      let isStart = column === 1 ? true : selected[column - 1].length === 0;
      let isEnd = column === 3 || selected[column + 1].length === 0;
      el.forEach(item => {
        const destItem = Object.values(taxonomies.children).find(el => el.order === column)
          .children[item];
        const destRow = destItem.order;
        const textWidth = getWidth(
          ctx,
          destItem.name.replace(/&amp;/g, '&'),
          16,
          "'Source Sans Pro', sans-serif",
        );
        if (column > 1) {
          selected[column - 1].forEach(itemPrev => {
            const origRow = Object.values(taxonomies.children).find(el => el.order === column - 1)
              .children[itemPrev].order;

            if (origRow) {
              paintConnection(ctx, origRow, column, destRow);
            }
          });
        }
        paintSelected(ctx, column, destRow, isStart, isEnd, textWidth);
      });
    }
  });
};

export const Canvas = ({ size, selected, taxonomies }) => {
  const canvasRef = useRef();

  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = BACKGROUND_COLOR;
    draw(context, selected, taxonomies);
  }, [size, selected, taxonomies]);

  return <CanvasStyled ref={canvasRef} width={size.width} height={size.height} />;
};
