import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const Container = styled.div`
  padding-right: 2rem;
  width: 275px;

  @media ${device.desktopLarge} {
    padding-right: 4rem;
    width: 325px;
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 160px;
`;

export const Title = styled.div`
  font-size: var(--font-size-title3);
  font-weight: var(--font-weight-semibold);
  line-height: 1.3;
  letter-spacing: 0.68px;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.regulations.filter.title};

  @media ${device.desktopLarge} {
    font-size: var(--font-size-title2);
  }
`;

export const FilterTitle = styled.div`
  color: ${({ theme }) => theme.regulations.filter.items};
  font-size: var(--font-size-title4);
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const FilterCollapse = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease;
  max-height: ${({ collapsed }) => (collapsed ? 0 : '1000px')};
`;

export const FilterContainer = styled.div`
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 1.225rem;
  margin-bottom: 1rem;
`;

export const FilterLabel = styled.label`
  color: ${({ theme }) => theme.regulations.filter.label};
  font-size: var(--font-size-caption1);
  display: flex;
  align-items: center;

  & + & {
    margin-top: 1.5rem;
  }

  &.disabled {
    color: ${({ theme }) => theme.regulations.filter.labelDisabled};
  }
`;

export const FilterCheckbox = styled.input`
  background: rgba(196, 214, 232, 0.22);
  border-radius: 5px;
  border: 1px solid #c4d6e8;
  margin: 0 0.5rem 0 0 !important;
  height: 20px;
  width: 20px !important;

  &:checked {
    background: rgba(196, 214, 232, 1);
  }

  &.disabled {
    background: rgb(213, 217, 222);
  }
`;

export const CollapseButton = styled.button`
  background: #19232d;
  border: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-left: 1rem;
  padding: 0;
  height: 21px;
  width: 21px;

  &:hover,
  &:focus {
    background: #19232d;
    border: none;
  }

  svg {
    transition: transform 0.5s ease;
    transform: rotate(-180deg);
    transform: ${({ collapsed }) => (collapsed ? 'rotate(-180deg)' : 'none')};
  }
`;
