import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ComposableMap, Geographies, ZoomableGroup } from 'react-simple-maps';

import { useQuantities, useDivisions, useElementObserver } from 'hooks/';
import * as optionActions from '../../../actions/optionActions';
import * as OPTIONS from '../../../helpers/options';
import { MapContainer, MapContent, MapOverlay } from './Maps.styles';
import { Spring } from 'react-spring/renderprops';
import { MapZoom, MapControls } from 'components/';

import world from '../../../styles/assets/world.json';

import { GeoLoader } from './GeoLoader';
import { GeographyItem } from './GeographyItem';
import {
  MAP_INITIAL_CENTER,
  MAP_INITIAL_ZOOM,
  MAP_INITIAL_POSITION,
  MAP_MIN_ZOOM,
  MAP_MAX_ZOOM,
  SPRING_FROM,
  SPRING_CONFIG,
  PROJECTION_CONFIG,
  MAP_FILL_COLOR,
  filterZoom,
  getSelectedGeoAndPosition,
} from './utils';

export const MapChart = ({ maps, filters }) => {
  const dispatch = useDispatch();
  const selected = useSelector(state => state.options?.[OPTIONS.KEYS.SELECTED_COUNTRY]?.selected);
  const [mapUrl, setMapUrl] = useState(world);
  const [global, setGlobal] = useState(true);
  const [animation, setAnimation] = useState(false);
  const [reset, setReset] = useState(false);
  const [position, setPosition] = useState(MAP_INITIAL_POSITION);
  const [geographiesList, setGeographiesList] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [canDisplay, setCanDisplay] = useState(false);
  const [maxZoom, setMaxZoom] = useState(MAP_MAX_ZOOM);
  const level = useSelector(state => state.options?.[OPTIONS.KEYS.REGULATION_LEVEL]?.selected);
  const [quantities] = useQuantities();
  const [divisions] = useDivisions();
  const observedRef = useElementObserver('map');

  const SPRING_TO = useMemo(() => ({ zoom: position.zoom }), [position]);

  const updateSelected = useCallback(
    selected => {
      dispatch(optionActions.updateSelectedOption(OPTIONS.KEYS.SELECTED_COUNTRY, selected));
    },
    [dispatch],
  );

  const resetMap = useCallback(() => {
    setMapUrl(world);
    setPosition(MAP_INITIAL_POSITION);
    setGlobal(true);
    setMaxZoom(MAP_MAX_ZOOM);
    if (selected && selected !== 'world') {
      updateSelected('world');
    }
    setReset(false);
  }, [selected, updateSelected]);

  useEffect(() => {
    if (geographiesList && loaded) {
      setTimeout(() => setCanDisplay(true), 50);
    }

    if (geographiesList && selected) {
      if (selected !== 'world') {
        const { geo, coordinates, zoom } = getSelectedGeoAndPosition(geographiesList, selected);
        if (geo) {
          if (selected in maps) {
            setMapUrl(maps[selected]);
            setGlobal(false);
          } else if (!global) {
            setGlobal(true);
            setMapUrl(world);
          }

          setPosition({ coordinates, zoom: zoom });

          if (zoom > maxZoom) {
            setMaxZoom(zoom);
          }
        }
      } else {
        resetMap();
      }
    }
  }, [selected, geographiesList, global, maps, maxZoom, loaded, resetMap]);

  useEffect(() => {
    if (canDisplay) {
      setAnimation(false);
    }
  }, [canDisplay]);

  const handleZoomIn = useCallback(() => {
    if (position.zoom >= MAP_MAX_ZOOM) return;
    setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
  }, [position.zoom]);

  const handleZoomOut = useCallback(() => {
    if (position.zoom <= MAP_MIN_ZOOM) {
      resetMap();
    }
    setPosition(pos => {
      if (pos.zoom / 2 < MAP_MIN_ZOOM) {
        return { coordinates: MAP_INITIAL_CENTER, zoom: MAP_INITIAL_ZOOM };
      } else return { ...pos, zoom: pos.zoom / 2 };
    });
  }, [position.zoom, resetMap]);

  const handleMoveStart = useCallback(() => {
    setAnimation(true);
  }, []);

  const handleMoveEnd = useCallback(position => {
    setPosition(position);
    setAnimation(false);
  }, []);

  const handleMapClick = useCallback(
    geo => {
      if (global && !Number.isInteger(geo.id)) {
        updateSelected(geo.id);
        if (level !== OPTIONS.REGULATION_LEVEL.municipal.key) {
          dispatch(
            optionActions.updateSelectedOption(
              OPTIONS.KEYS.REGULATION_LEVEL,
              OPTIONS.REGULATION_LEVEL.state.key,
            ),
          );
        }
      }
    },
    [global, level, updateSelected, dispatch],
  );

  const handleMouseEnter = useCallback(
    geo => {
      if (global) {
        if (!Number.isInteger(geo.id)) {
        }
      }
    },
    [global],
  );

  const handleMouseLeave = useCallback(() => {
    if (global) {
    }
  }, [global]);

  const getGeographyColor = useCallback(
    geo => {
      if (Number.isInteger(geo.id) && !geo.properties.slug) {
        return MAP_FILL_COLOR.NO;
      }
      if (geo.id && !geo.properties.slug) {
        let country = geo.id.toLowerCase();
        if (quantities !== null && !!quantities?.[country]) {
          if (level === OPTIONS.REGULATION_LEVEL.all.key) {
            return (
              quantities[country][OPTIONS.REGULATION_LEVEL.national.key] > 0 ||
              quantities[country][OPTIONS.REGULATION_LEVEL.regional.key] > 0 ||
              quantities[country][OPTIONS.REGULATION_LEVEL.state.key] > 0 ||
              quantities[country][OPTIONS.REGULATION_LEVEL.municipal.key] > 0
            );
          }
          return quantities[country][level] > 0 ? MAP_FILL_COLOR.YES : MAP_FILL_COLOR.NO;
        }
        return MAP_FILL_COLOR.NO;
      } else {
        let state = geo.properties.slug;
        if (
          divisions &&
          (level === OPTIONS.REGULATION_LEVEL.state.key ||
            level === OPTIONS.REGULATION_LEVEL.municipal.key ||
            level === OPTIONS.REGULATION_LEVEL.all.key)
        ) {
          if (divisions.states?.[state]) {
            return MAP_FILL_COLOR.YES_INNER;
          }
        }
        return quantities?.[selected?.toLowerCase()]?.[OPTIONS.REGULATION_LEVEL.national.key] > 0
          ? MAP_FILL_COLOR.YES
          : MAP_FILL_COLOR.NO;
      }
    },
    [level, quantities, divisions, selected],
  );

  const handleGeographiesLoad = useCallback(
    items => {
      setGeographiesList(items);
      setPosition(getSelectedGeoAndPosition(items, selected));
      setLoaded(true);
    },
    [selected],
  );

  return (
    <MapContainer ref={observedRef} id="map">
      <MapContent>
        <MapZoom handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />
        <MapControls filters={filters} />
        <MapOverlay />
        <ComposableMap projection="geoMercator" projectionConfig={PROJECTION_CONFIG}>
          <GeoLoader onLoad={handleGeographiesLoad} />
          {canDisplay && (
            <Spring
              from={SPRING_FROM}
              immediate={animation}
              to={SPRING_TO}
              config={SPRING_CONFIG}
              reset={reset}
            >
              {styles => (
                <ZoomableGroup
                  zoom={styles.zoom}
                  minZoom={MAP_MIN_ZOOM}
                  maxZoom={maxZoom}
                  center={position.coordinates}
                  onMoveStart={handleMoveStart}
                  onMoveEnd={handleMoveEnd}
                  filterZoomEvent={filterZoom}
                >
                  <Geographies geography={mapUrl}>
                    {({ geographies }) =>
                      geographies.map(geo => (
                        <GeographyItem
                          key={geo.rsmKey}
                          geography={geo}
                          onClick={handleMapClick}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          getGeographyColor={getGeographyColor}
                        />
                      ))
                    }
                  </Geographies>
                </ZoomableGroup>
              )}
            </Spring>
          )}
        </ComposableMap>
      </MapContent>
    </MapContainer>
  );
};
