import React from 'react';
import { useSelector } from 'react-redux';

import * as OPTIONS from 'helpers/options';
import { List, ListTitle, Tooltip } from './styles';
import { ListItem } from 'components/';
import { ReactComponent as Info } from 'styles/assets/images/info.svg';

export const Lists = ({
  id,
  title = 'Regulation',
  description,
  options,
  enabledMap = null,
  index = 0,
  handleAdd,
  handleRemove,
}) => {
  const selected = useSelector(
    state => state.options?.[OPTIONS.KEYS.REGULATIONS_SELECTED]?.selected[index],
  );

  return (
    <List>
      <ListTitle data-tooltip={description ? true : null}>
        {title}
        {description && (
          <>
            <Info />
            <Tooltip>{description}</Tooltip>
          </>
        )}
      </ListTitle>
      {Object.keys(options).map((opt, ix) => (
        <ListItem
          key={ix}
          label={options[opt].name}
          keyOption={options[opt].id}
          column={index}
          selected={selected.includes(options[opt].id)}
          handleAdd={() => handleAdd(OPTIONS.KEYS.REGULATIONS_SELECTED, index, options[opt].id)}
          handleRemove={() =>
            handleRemove(OPTIONS.KEYS.REGULATIONS_SELECTED, index, options[opt].id)
          }
          enabled={
            !!enabledMap && Object.keys(enabledMap).length > 0
              ? enabledMap[id]?.[options[opt].id]
              : index === 0
          }
        />
      ))}
    </List>
  );
};
