// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { useGeographies } from 'react-simple-maps';
import world from '../../../styles/assets/world.json';

export const GeoLoader = ({ onLoad }) => {
  const { geographies } = useGeographies({ geography: world });
  useEffect(() => onLoad(geographies), [onLoad, geographies]);
  return null;
};
