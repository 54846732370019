import { useState, useEffect } from 'react';
import FilterController from '../controllers/FiltersController';

export const useFilters = () => {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);
      const result = await FilterController.fetchFilters();
      setData(result);
      setLoaded(true);
    };
    fetchData();
  }, []);

  return [data, loaded];
};
