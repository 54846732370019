import styled from 'styled-components/macro';
import { BaseButton } from 'components/';

export const Container = styled.div`
  display: ${({ enabled }) => (enabled ? 'flex' : 'none')};
`;

export const Button = styled(BaseButton)`
  font-weight: ${({ selected }) =>
    selected ? 'var(--font-weight-bold);' : 'font-weight: var(--font-weight-regular);'};
  font-size: var(--font-size-caption1);
  line-height: var(--line-height-caption1);
  letter-spacing: 1.75px;
  text-transform: uppercase;
  padding: 8px 10px;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.regulations.pagination.color};

  :hover {
    background-color: ${({ theme }) => theme.regulations.pagination.hoverBackground};
  }

  :active {
    background-color: ${({ theme }) => theme.regulations.pagination.activeBackground};
  }
`;
