import React from 'react';

import { Container, Content, Text, Image } from './Footer.styles';
import logo from '../../../styles/assets/images/icnlLogo.png';
import { FOOTER_TEXT } from '../../../helpers/content';

export const Footer = () => (
  <Container>
    <footer className="footer">
      <section className="footer-panel bg--outer-space">
        <div className="container">
          <div className="region region-footer-center">
            <div
              id="block-block-17"
              className="block block-block contextual-links-region footer-panel__top__item"
            >
              <h3 className="footer-panel__title">Our Address</h3>
              <div className="logo-footer">
                <a href="https://fsi.stanford.edu">
                  <img
                    height="52"
                    src="https://cyber.fsi.stanford.edu/sites/all/themes/fsi/images/logo-white.png"
                    width="517"
                    alt="Stanford logo"
                  />
                </a>
              </div>
              <div className="footer-panel__body">
                Encina Hall
                <br />
                616 Jane Stanford Way
                <br />
                Stanford University
                <br />
                Stanford, CA 94305-6055
              </div>
            </div>
            <div
              id="block-block-19"
              className="block block-block contextual-links-region footer-panel__top__item"
            >
              <h3 className="footer-panel__title">Navigate</h3>
              <div className="nav-footer">
                <ul>
                  <li>
                    <a href="http://fsi.stanford.edu/content/research">Research</a>
                  </li>
                  <li>
                    <a href="http://fsi.stanford.edu/content/education">Education</a>
                  </li>
                  <li>
                    <a href="http://fsi.stanford.edu/people/faculty">People</a>
                  </li>
                  <li>
                    <a href="http://fsi.stanford.edu/content/our-centers">Centers</a>
                  </li>
                  <li>
                    <a href="http://fsi.stanford.edu/news">News</a>
                  </li>
                  <li>
                    <a href="http://fsi.stanford.edu/events">Events</a>
                  </li>
                  <li>
                    <a href="http://fsi.stanford.edu/docs/about_fsi">About</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="block-block-18"
              className="block block-block contextual-links-region footer-panel__top__item"
            >
              <h3 className="footer-panel__title">Follow Us</h3>
              <p>
                <strong>General inquiries</strong>
                <br />
                650-723-4581
              </p>
              <div className="social-links">
                <ul>
                  <li>
                    <a
                      className="icon-envelope"
                      href="https://fsi.stanford.edu/content/get-connected"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                    </a>
                  </li>
                  <li>
                    <a
                      className="icon-twitter"
                      href="https://twitter.com/FSIStanford"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                    </a>
                  </li>
                  <li>
                    <a
                      className="icon-facebook-f"
                      href="https://www.facebook.com/StanfordFSI"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                    </a>
                  </li>
                  <li>
                    <a
                      className="icon-youtube-play"
                      href="https://www.youtube.com/channel/UCB2X9yR4GZLfrCe4jChIEwg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                    </a>
                  </li>
                  <li>
                    <a
                      className="icon-instagram"
                      href="https://www.instagram.com/fsi_stanford"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                    </a>
                  </li>
                </ul>
              </div>
              <p>&nbsp;</p>
            </div>
            <div
              id="block-block-20"
              className="block block-block contextual-links-region footer-panel__top__item"
            >
              <h3 className="footer-panel__title">Support Us</h3>
              <div className="support-us">
                <p>Learn more about how your support makes a difference or make a gift now</p>
                <a className="btn btn--small" href="http://fsi.stanford.edu/docs/supporting_fsi">
                  Make a gift
                </a>
              </div>
              <p> </p>
            </div>
          </div>
        </div>
      </section>
      <div id="back-top" className="back-top hidden-on-tablet">
        <div className="back-top__inner">
          <span className="back-top__item back-top__icon js-back-top icon-chevron-up"></span>
          <span className="back-top__item">Top</span>
        </div>
      </div>
      <section className="footer-global bg--cardinal">
        <div className="container">
          <div className="region region-footer-copyrights">
            <div id="block-imageblock-1" className="block block-imageblock contextual-links-region">
              <div className="content">
                <div className="footer-global__logo">
                  <img
                    className="imageblock-image"
                    typeof="foaf:Image"
                    src="https://fsi-live.s3.us-west-1.amazonaws.com/s3fs-public/imageblock/footer-stanford-logo2x.png"
                    alt="Logo footer"
                    title="footer-stanford-logo"
                  />
                </div>
                <nav className="footer-global__nav" role="navigation">
                  <ul className="footer-global__nav__bold">
                    <li>
                      <a
                        href="https://www.stanford.edu/"
                        rel="noopener noreferrer"
                        title="Stanford Home"
                        target="_blank"
                      >
                        Stanford Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://visit.stanford.edu/plan/"
                        rel="noopener noreferrer"
                        title="Maps &amp; Directions"
                        target="_blank"
                      >
                        Maps &amp; Directions
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.stanford.edu/search/"
                        rel="noopener noreferrer"
                        title="Search Stanford"
                        target="_blank"
                      >
                        Search Stanford
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://emergency.stanford.edu/"
                        rel="noopener noreferrer"
                        title="Emergency Info"
                        target="_blank"
                      >
                        Emergency Info
                      </a>
                    </li>
                  </ul>
                  <ul className="secondUl">
                    <li>
                      <a
                        href="https://www.stanford.edu/site/terms/"
                        rel="noopener noreferrer"
                        title="Terms of use for sites"
                        target="_blank"
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.stanford.edu/site/privacy/"
                        rel="noopener noreferrer"
                        title="Privacy and cookie policy"
                        target="_blank"
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://uit.stanford.edu/security/copyright-infringement"
                        rel="noopener noreferrer"
                        title="Report alleged copyright infringement"
                        target="_blank"
                      >
                        Copyright
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://adminguide.stanford.edu/chapter-1/subchapter-5/policy-1-5-4"
                        rel="noopener noreferrer"
                        title="Ownership and use of Stanford trademarks and images"
                        target="_blank"
                      >
                        Trademarks
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://exploredegrees.stanford.edu/nonacademicregulations/nondiscrimination/"
                        rel="noopener noreferrer"
                        title="Non-discrimination policy"
                        target="_blank"
                      >
                        Non-Discrimination
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.stanford.edu/site/accessibility/"
                        rel="noopener noreferrer"
                        title="Report web accessibility issues"
                        target="_blank"
                      >
                        Accessibility
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="footer-global__copyright">
                  <p className="vcard">
                    © <span className="fn org">Stanford University</span>,&nbsp;
                    <span className="adr">
                      <span className="locality">Stanford</span>,&nbsp;
                      <span className="region">California</span>
                      <span className="postal-code">94305</span>
                    </span>
                    .&nbsp;
                    <span className="copyright-links">
                      <a href="http://www.stanford.edu/site/copyright.html">Copyright Complaints</a>
                    </span>
                  </p>
                </div>
                <Content>
                  <Text>{FOOTER_TEXT}</Text>
                  <Image>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.icnl.org/">
                      <img src={logo} alt="ICNL" />
                    </a>
                  </Image>
                </Content>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  </Container>
);
