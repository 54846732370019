import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  box-sizing: border-box;

  @media only screen and (max-width: 450px) {
    padding: 16px 20px 13px;
    border-radius: ${({ open }) => `20px 20px ${open ? '0px' : '20px'} ${open ? '0px' : '20px'}`};
  }
`;

export const Flag = styled.div`
  width: 33px;
  height: 33px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 17px;

  img {
    position: absolute;
    height: 33px;
    width: 33px;
  }
`;

export const ContainerText = styled.span`
  color: ${({ theme }) => theme.filters.item};
  font-size: var(--font-size-title5);
  font-weight: var(--font-weight-semibold);
  letter-spacing: 0.38px;
  line-height: 1.07;
  position: relative;
  text-align: left;
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media ${device.desktopLarge} {
    font-size: var(--font-size-title4);
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;

  svg {
    transform: ${({ open }) => `rotate(${open ? 180 : 0}deg)`};
    fill: ${({ theme }) => theme.regulations.select.arrowColor};
    height: 30px;
    width: 30px;
  }
`;

export const ItemsContainerSpacer = styled.div`
  z-index: 2;
  position: absolute;
  padding: 20px 0;
  top: calc(100% + 5px);
  left: 0px;
  width: 266px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.regulations.select.backgroundOpen};
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.regulations.select.border};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

export const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 400px;

  @media only screen and (max-width: 450px) {
    border-radius: 0px 0px 20px 20px;
  }

  ::-webkit-scrollbar {
    padding-top: 5px;
    width: 30px;
    background-color: ${({ theme }) => theme.regulations.select.scroll.background};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border: solid 13px ${({ theme }) => theme.regulations.select.scroll.background};
    border-top: 0;
    border-bottom: 0;
    background-color: ${({ theme }) => theme.regulations.select.scroll.thumb};
    background-clip: padding-box;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track-piece {
    border: solid 14px ${({ theme }) => theme.regulations.select.scroll.background};
    border-radius: 3px;
    background-color: ${({ theme }) => theme.regulations.select.scroll.line};
  }
`;

export const Item = styled.div`
  font-weight: ${({ selected }) =>
    selected ? 'var(--font-weight-semibold)' : 'var(--font-weight-regular)'};
  font-size: var(--font-size-caption1);
  line-height: 1.14;
  letter-spacing: 1px;
  position: relative;
  box-sizing: content-box;
  flex: 0 0 auto;
  color: ${({ selected, theme }) =>
    selected ? theme.regulations.select.itemSelectedColor : theme.regulations.select.itemColor};
  padding: 9px 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.regulations.select.highlightColor};
  }

  & > svg {
    height: 14px;
    width: 14px;
    path {
      fill: ${({ theme }) => theme.regulations.select.checkmark} !important;
    }
  }
`;

export const Clear = styled.div`
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-caption2);
  line-height: var(--line-height-caption1);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: 8px 20px;
  color: ${({ theme }) => theme.regulations.select.clear};
`;
