import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const TitleContainer = styled.div`
  font-size: var(--font-size-title1);
  line-height: var(--line-height-title1);
  font-weight: var(--font-weight-light);
  letter-spacing: 0.01px;
  color: ${({ theme }) => theme.filters.titleLight};
`;

export const SubheaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 2;
`;

export const SubheaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    max-width: 735px;
  }
`;

export const SubheaderDescription = styled.div`
  font-size: var(--font-size-title3);
  line-height: var(--line-height-title3);
  letter-spacing: 0.4px;
  margin-top: 15px;
  color: ${({ theme }) => theme.filters.description};

  blockquote {
    font-size: var(--font-size-primary);
    margin-top: 1.5rem;

    p {
      font-weight: var(--font-weight-regular);
    }

    a {
      color: ${({ theme }) => theme.filters.button.hover};
    }
  }

  > p:last-child {
    a {
      background-color: ${({ theme }) => theme.filters.button.background};
      border-radius: 2rem;
      color: ${({ theme }) => theme.filters.button.color};
      display: inline-block;
      font-size: var(--font-size-primary);
      font-weight: var(--font-weight-bold);
      letter-spacing: 0.53px;
      margin-top: 1.5rem;
      padding: 0.3rem 2rem;
      transition: all 0.5s ease;

      &:hover {
        background-color: ${({ theme }) => theme.filters.button.hover};
        color: ${({ theme }) => theme.filters.button.color};
        text-decoration: none;
      }
    }
  }
`;
