import React, { useCallback } from 'react';

import * as OPTIONS from 'helpers/options';
import { CountrySelect } from 'components/';
import { FilterContainer, FilterGrid, FilterNavigation, FilterItem, FilterLink } from './styles';
import { Container } from '../Taxonomies.styles';

const FILTERS_TOP_LEVEL_NAMES = ['AI Regulation', 'Platform Regulation'];
const STICKY_OFFSET = -128;

export const Filter = ({
  taxonomies,
  countries,
  selected,
  flags,
  onCategorySelect,
  onCountrySelect,
}) => {
  const handleSelectCategory = useCallback(
    id => {
      onCategorySelect(OPTIONS.KEYS.REGULATIONS_SELECTED, 0, id);
    },
    [onCategorySelect],
  );

  const updateSelectedCountry = useCallback(
    selected => {
      onCountrySelect(selected);
    },
    [onCountrySelect],
  );

  return (
    <FilterContainer>
      <Container>
        <FilterGrid>
          <FilterNavigation>
            {Object.values(taxonomies).map((cat, ix) => (
              <FilterItem
                key={`filter-${ix}`}
                className={`${selected[0]?.[0] === cat.id ? 'active' : ''}`}
                onClick={() => handleSelectCategory(cat.id)}
              >
                {FILTERS_TOP_LEVEL_NAMES[ix]}
              </FilterItem>
            ))}
          </FilterNavigation>
          <FilterNavigation>
            <CountrySelect onSelect={updateSelectedCountry} items={countries} flags={flags} />
          </FilterNavigation>
          <FilterNavigation>
            <FilterLink to="flowchart" offset={STICKY_OFFSET} smooth data-target="flowchart">
              Flowchart
            </FilterLink>
            <FilterLink to="map" offset={STICKY_OFFSET} smooth data-target="map">
              Map
            </FilterLink>
            <FilterLink to="literature" offset={STICKY_OFFSET} smooth data-target="literature">
              Literature
            </FilterLink>
          </FilterNavigation>
        </FilterGrid>
      </Container>
    </FilterContainer>
  );
};
