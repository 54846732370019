import React from 'react';

import { Spinner } from 'components/';
import { Container, SpinnerContainer } from './Regulations.styles';
import { useRegulations } from 'hooks/';
import { Regulations } from './Regulations';

export const RegulationsLoader = ({ countries }) => {
  const [data, isLoading, page, totalPages, setPage] = useRegulations();

  if (!data) {
    return (
      <Container>
        <SpinnerContainer>
          <Spinner size={70} />
        </SpinnerContainer>
      </Container>
    );
  }
  return (
    <Container>
      <Regulations
        regulations={data}
        page={page}
        totalPages={totalPages}
        onSetPage={setPage}
        loading={isLoading}
        countries={countries}
      />
    </Container>
  );
};
