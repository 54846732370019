import styled from 'styled-components/macro';

export const ControlsContainer = styled.div`
  width: 266px;
  min-width: 266px;
  position: relative;
  padding-top: 45px;
  margin-right: 15px;
`;

export const LegendContainer = styled.div`
  width: 100%;
  margin: 25px 0;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;
`;

export const LegendColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 13px;
  background-color: ${({ isNegativeLabel, theme }) =>
    isNegativeLabel ? theme.map.legend.colorNo : theme.map.legend.colorYes};
`;

export const LegendText = styled.div`
  letter-spacing: 0.6px;
  max-width: 110px;
  color: ${({ theme }) => theme.map.legend.text};
`;

export const LegendFilter = styled.button`
  background: ${({ theme }) => theme.map.legendFilter.background};
  border: none;
  border-radius: 30px;
  display: block;
  text-transform: initial;
  color: ${({ theme }) => theme.map.legendFilter.text};
  cursor: default;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-caption1);
  padding: 0.5rem 1rem;
  padding-right: 2.5rem;
  position: relative;
  max-width: 240px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  & + & {
    margin-top: 0.9rem;
  }

  &:hover,
  &:focus {
    background: rgba(196, 214, 232, 0.5);
    color: ${({ theme }) => theme.map.legendFilter.text};
  }

  svg {
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
  }
`;
