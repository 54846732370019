import { css } from 'styled-components';

const Typography = css`
  :root {
    --color-primary: #4f6071;
    --font-family-primary: 'Source Sans Pro', sans-serif;
    --font-size-primary: 16px;
    --font-size-title1: 48px;
    --font-size-title2: 32px;
    --font-size-title3: 24px;
    --font-size-title4: 18px;
    --font-size-caption1: 14px;
    --font-size-caption2: 12px;
    --font-size-caption3: 10px;
    --line-height-title1: 50px;
    --line-height-title2: 40px;
    --line-height-title3: 32px;
    --line-height-title4: 24px;
    --line-height-caption1: 16px;
    --line-height-caption2: 14px;
    --line-height-caption3: 12px;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
  }
`;

export default Typography;
