import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const ItemContainer = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: ${({ selected, isFirst, theme }) =>
    (isFirst && selected && '0 7px 0 16px') || (isFirst && '0 16px') || '0'};
  background-color: ${({ selected, isFirst, theme }) =>
    selected && isFirst ? theme.filters.taxonomies.backgroundSelected : 'transparent'};
  border: ${({ selected, isFirst, theme }) =>
    isFirst && !selected ? `1px solid ${theme.filters.taxonomies.borderFirst}` : 'none'};
  margin-bottom: ${({ isFirst, theme }) => (isFirst ? '9px' : '0')};
  cursor: ${({ selected, disabled }) =>
    (disabled && 'not-allowed') || (!selected && 'pointer') || 'default'};
  z-index: 1;
`;

export const CrossContainer = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

export const Label = styled.div`
  font-weight: ${({ isFirst, enabled }) =>
    isFirst || enabled ? 'var(--font-weight-semibold)' : 'var(--font-weight-regular)'};
  font-size: var(--font-size-caption1);
  line-height: var(--line-height-caption1);
  white-space: nowrap;
  letter-spacing: 0.53px;
  z-index: 1;
  color: ${({ selected, isFirst, enabled, theme }) => {
    if (isFirst) {
      return selected
        ? theme.filters.taxonomies.labelFirstSelected
        : theme.filters.taxonomies.label;
    } else {
      if (!enabled) {
        return theme.filters.taxonomies.labelDisabled;
      } else {
        return selected
          ? theme.filters.taxonomies.labelRestSelected
          : theme.filters.taxonomies.label;
      }
    }
  }};

  @media ${device.desktopLarge} {
    font-size: var(--font-size-primary);
  }
`;
