import { useState, useEffect } from 'react';

export const useTaxonomies = ({ taxonomies, regulations, category, level, country }) => {
  const [data, setData] = useState(null);
  const [loadedData, setLoadedData] = useState(false);

  useEffect(() => {
    setLoadedData(false);
    if (!!taxonomies && !!regulations) {
      const filtered = Object.keys(regulations)
        .filter(
          (reg) =>
            (level === 'all' || regulations[reg].region === level) &&
            regulations[reg].types.includes(category.toString()) &&
            (country === undefined ||
              country === 'world' ||
              regulations[reg].country.includes(country)),
        )
        .reduce((obj, key) => {
          obj[key] = regulations[key];
          return obj;
        }, {});
      setData(filtered);
      setLoadedData(true);
    }
  }, [taxonomies, regulations, category, level, country]);

  return [data, loadedData];
};
