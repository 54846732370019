import React from 'react';

import * as CONTENT from '../../helpers/content';

import {
  SubheaderContainer,
  SubheaderTextContainer,
  TitleContainer,
  SubheaderDescription,
} from './Subheader.styles';

export const Subheader = ({ description, error }) => (
  <SubheaderContainer>
    <SubheaderTextContainer>
      <TitleContainer isDark>{CONTENT.TITLE}</TitleContainer>
      {!error ? (
        <SubheaderDescription dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <SubheaderDescription>
          Sorry, we had a problem to load regulations, please try again or contact support.
        </SubheaderDescription>
      )}
    </SubheaderTextContainer>
  </SubheaderContainer>
);
