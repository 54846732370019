import { useState, useEffect, useCallback } from 'react';

export const useCustomSelect = ({ selected, items }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    !items.length && setOpen(false);
  }, [items.length]);

  const handleOpen = useCallback(() => items.length && setOpen((current) => !current), [
    items.length,
  ]);

  const selectedItem = items ? items.find((item) => item.key === selected) : null;
  const disabled = !items.length;

  return [open, selectedItem, disabled, handleOpen];
};
