import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuantities } from 'hooks/';
import * as optionActions from 'actions/optionActions';
import * as OPTIONS from 'helpers/options';
import {
  Container,
  StickyContainer,
  Title,
  FilterCollapse,
  FilterContainer,
  FilterTitle,
  FilterLabel,
  FilterCheckbox,
  CollapseButton,
} from './RegulationsFilter.styles';
import { ReactComponent as ChevronDown } from 'styles/assets/images/chevron-down.svg';

const FilterItem = ({ label, handleFilter, quantity, selected }) => {
  if (quantity === 0 && !selected) return null;

  return (
    <FilterLabel className={quantity === 0 ? 'disabled' : ''} onClick={handleFilter}>
      <FilterCheckbox
        className={quantity === 0 ? 'disabled' : ''}
        type="checkbox"
        checked={selected}
        onChange={handleFilter}
      />
      {label} ({quantity})
    </FilterLabel>
  );
};

const Filters = ({ title, results, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = useCallback(() => {
    setCollapsed(val => !val);
  }, []);

  return (
    <>
      <FilterTitle onClick={handleCollapse}>
        {title}
        <CollapseButton collapsed={collapsed}>
          <ChevronDown />
        </CollapseButton>
      </FilterTitle>
      <FilterCollapse collapsed={collapsed}>
        <FilterContainer>{children}</FilterContainer>
      </FilterCollapse>
    </>
  );
};

const FiltersMap = ({ handleFilter }) => {
  const keysRegulationLevel = OPTIONS.KEYS.REGULATION_LEVEL;
  const selected = useSelector(state => state.options?.[keysRegulationLevel]?.selected);
  const country = useSelector(
    state => state.options?.[OPTIONS.KEYS.SELECTED_COUNTRY]?.selected,
  ).toLowerCase();
  const [quantities] = useQuantities();

  return Object.keys(OPTIONS.REGULATION_LEVEL).map(key => (
    <FilterItem
      key={OPTIONS.REGULATION_LEVEL[key]?.order}
      label={OPTIONS.REGULATION_LEVEL[key]?.label}
      handleFilter={() => handleFilter(keysRegulationLevel, OPTIONS.REGULATION_LEVEL[key].key)}
      quantity={quantities?.[country]?.[key] ?? 0}
      selected={OPTIONS.REGULATION_LEVEL[key]?.key === selected}
    />
  ));
};

const YearsMap = ({ year, handleFilter }) => {
  const keysRegulationYear = OPTIONS.KEYS.REGULATIONS_YEAR;
  const selected = useSelector(state => state.options?.[keysRegulationYear]?.selected);

  return Object.keys(year)
    .reverse()
    .map(key => (
      <FilterItem
        key={key}
        label={key}
        handleFilter={() => handleFilter(keysRegulationYear, key === selected ? null : key)}
        quantity={year[key] ?? 0}
        selected={key === selected}
      />
    ));
};

export const RegulationsFilter = ({ years, results }) => {
  const dispatch = useDispatch();

  const updateSelected = useCallback(
    (option, selected) => {
      dispatch(optionActions.updateSelectedOption(option, selected));
    },
    [dispatch],
  );

  return (
    <Container>
      <StickyContainer>
        <Title>Analysis of Regulations</Title>
        <Filters title="Level of Jurisdiciton" results={results}>
          <FiltersMap handleFilter={updateSelected} />
        </Filters>
        <Filters title="Year Enacted" results={results}>
          <YearsMap year={years} handleFilter={updateSelected} />
        </Filters>
      </StickyContainer>
    </Container>
  );
};
