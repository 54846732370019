import { ACTION_TYPES } from '../actions/optionActions';
import { KEYS, REGULATION_LEVEL } from '../helpers/options';

const INITIAL_STATE = {
  [KEYS.SELECTED_COUNTRY]: {
    selected: 'world',
  },
  [KEYS.REGULATIONS_SELECTED]: {
    selected: [],
  },
  [KEYS.REGULATIONS_YEAR]: {
    selected: null,
  },
  [KEYS.REGULATION_LEVEL]: {
    selected: REGULATION_LEVEL.national.key,
  },
  [KEYS.REGULATION_CATEGORY]: {
    selected: ['All Regulations'],
  },
};

export const getChangedOptions = state => {
  const difference = {};
  Object.keys(state).forEach(key => {
    if (
      state[key].selected !== null &&
      (!Array.isArray(state[key].selected) || state[key].selected.length > 0)
    ) {
      if (key === KEYS.REGULATION_LEVEL || key === KEYS.SELECTED_COUNTRY) {
        if (INITIAL_STATE[key].selected !== state[key].selected) {
          difference[key] = state[key].selected;
        }
      } else if (key === KEYS.REGULATION_CATEGORY) {
        if (state[key].selected.length > 1) {
          difference[key] = state[key].selected[0];
        }
      } else {
        difference[key] = state[key].selected;
      }
    }
  });
  return difference;
};

const updateSelectedOption = (state, action) => {
  return {
    ...state,
    [action.option]: {
      ...state[action.option],
      selected: action.selectedKey,
    },
  };
};

const addOptionArray = (state, action) => {
  const newSelected = [action.selectedKey, ...INITIAL_STATE[action.option].selected];
  return {
    ...state,
    [action.option]: {
      ...state[action.option],
      selected: newSelected,
    },
  };
};

const clearOptionArray = (state, action) => {
  return {
    ...state,
    [action.option]: {
      ...state[action.option],
      selected: [...INITIAL_STATE[action.option].selected],
    },
  };
};

const resetFilters = state => {
  return {
    ...INITIAL_STATE,
    [KEYS.SELECTED_COUNTRY]: {
      selected: state[KEYS.SELECTED_COUNTRY].selected,
    },
  };
};

const addSelectedOption = (state, action) => {
  if (action.column === 0) {
    return {
      ...state,
      [action.option]: {
        selected: [[action.selectedKey], [], [], []],
      },
    };
  } else {
    const newSelected = state[action.option].selected.map((elem, index) => {
      if (index === action.column && !elem.includes(action.selectedKey)) {
        return [...elem, action.selectedKey];
      }
      return elem;
    });
    return {
      ...state,
      [action.option]: {
        ...state[action.option],
        selected: newSelected,
      },
    };
  }
};

const removeSelectedOption = (state, action) => {
  if (action.column === 0) {
    return {
      ...state,
      [action.option]: {
        selected: [],
      },
    };
  } else {
    const newSelected = state[action.option].selected.map((elem, index) => {
      if (index === action.column) {
        const found = elem.indexOf(action.selectedKey);
        if (found > -1) {
          elem.splice(found, 1);
        }
        return elem;
      }
      return elem;
    });
    return {
      ...state,
      [action.option]: {
        ...state[action.option],
        selected: newSelected,
      },
    };
  }
};

const setSelectedRegulations = (state, action) => {
  return {
    ...state,
    [action.option]: {
      ...state[action.option],
      selected: [...action.selected],
    },
  };
};

const HANDLERS = {
  [ACTION_TYPES.UPDATE_SELECTED_OPTION]: updateSelectedOption,
  [ACTION_TYPES.RESET_FILTERS]: resetFilters,
  [ACTION_TYPES.ADD_SELECTED_OPTION]: addSelectedOption,
  [ACTION_TYPES.REMOVE_SELECTED_OPTION]: removeSelectedOption,
  [ACTION_TYPES.ADD_OPTION_ARRAY]: addOptionArray,
  [ACTION_TYPES.CLEAR_OPTION_ARRAY]: clearOptionArray,
  [ACTION_TYPES.SET_SELECTED_REGULATIONS]: setSelectedRegulations,
};

export default (state = INITIAL_STATE, action) => HANDLERS?.[action.type]?.(state, action) ?? state;
