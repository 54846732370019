import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import RegulationsController from '../controllers/RegulationsController';
import * as OPTIONS from '../helpers/options';

export const useRegulations = () => {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const country = useSelector(state => state.options?.[OPTIONS.KEYS.SELECTED_COUNTRY]?.selected);
  const year = useSelector(state => state.options?.[OPTIONS.KEYS.REGULATIONS_YEAR]?.selected);
  const level = useSelector(state => state.options?.[OPTIONS.KEYS.REGULATION_LEVEL]?.selected);
  const types = useSelector(state => state.options?.[OPTIONS.KEYS.REGULATIONS_SELECTED]?.selected);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await RegulationsController.fetchRegulations(
        country,
        year,
        level,
        types,
        page,
      );
      setData(result);
      setTotalPages(result.pagination_data.total_pages);
      setIsLoading(false);
    };

    if (types.length > 0) {
      fetchData();
    }
  }, [country, year, level, types, page]);

  return [data, isLoading, page, totalPages, setPage];
};
