import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Qs from 'qs';

import { useDispatch } from 'react-redux';
import { useMediaQuery, useFilters } from 'hooks/';

import * as optionActions from '../../actions/optionActions';
import * as OPTIONS from '../../helpers/options';
import { Filters } from './Filters/Filters';
import { MapsLoader } from './Map/MapsLoader';
import { RegulationsLoader } from './Regulations/RegulationsLoader';
import { Spinner } from 'components/';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Container, ContainerWrapper, SpinnerContainer } from './Dashboard.styles';
import world from '../../styles/assets/world.json';

const VISIBILITY_THRESHOLD = 800;

const DashboardContent = ({ mobileMode }) => {
  const [countries, setCountries] = useState(null);
  const [data, loaded] = useFilters();
  const [flags, setFlags] = useState(undefined);

  useEffect(() => {
    const selectList = world.objects.world_v3_2.geometries
      .filter(country => !Number.isInteger(country.id))
      .map(country => ({
        label: country.properties.name,
        key: country.id,
      }));
    selectList.sort((a, b) => a.label.localeCompare(b.label));
    selectList.unshift({ label: 'World', key: 'world' });
    setCountries(selectList);
  }, []);

  useEffect(() => {
    Promise.all([require.context('../../styles/assets/images/flags', false, /\.(svg|png)$/)]).then(
      jsonList => {
        jsonList = jsonList[0];
        setFlags(
          jsonList.keys().reduce((images, path) => {
            images[path.split('/').pop().split('.')[0]] = jsonList(path);
            return images;
          }, {}),
        );
      },
    );
  }, []);

  if (!loaded || !countries || !flags) {
    return (
      <SpinnerContainer>
        <Spinner size={70} />
      </SpinnerContainer>
    );
  }
  return (
    <ContainerWrapper>
      <Header />
      <Filters
        taxonomies={data.filters}
        regulations={data.regulations}
        background={data.background}
        description={data.intro_text}
        countries={countries}
        flags={flags}
      />
      {typeof data.filters !== 'undefined' && (
        <>
          <MapsLoader filters={data.filters} />
          <RegulationsLoader countries={countries} />
        </>
      )}
      <Footer />
    </ContainerWrapper>
  );
};

export const Dashboard = () => {
  const mobileMode = useMediaQuery({ max: VISIBILITY_THRESHOLD });
  const dispatch = useDispatch();
  const params = Qs.parse(useLocation().search, { ignoreQueryPrefix: true, parseArrays: false });
  const history = useHistory();

  // eslint-disable-next-line
  useEffect(() => {
    history.replace({});
    Object.keys(params).forEach(key => {
      switch (key) {
        case 'selected_country':
          dispatch(optionActions.updateSelectedOption(OPTIONS.KEYS.SELECTED_COUNTRY, params[key]));
          break;
        case 'level':
          dispatch(optionActions.updateSelectedOption(OPTIONS.KEYS.REGULATION_LEVEL, params[key]));
          break;
        case 'year':
          dispatch(optionActions.updateSelectedOption(OPTIONS.KEYS.REGULATIONS_YEAR, params[key]));
          break;
        case 'category':
          dispatch(optionActions.addOptionArray(OPTIONS.KEYS.REGULATION_CATEGORY, params[key]));
          break;
        case 'regulations':
          for (let i = 0; i < 4; i++) {
            if (!params[key][i]) {
              params[key][i] = {};
            }
          }
          const regulations = Object.keys(params[key]).map(keyChild =>
            Object.values(params[key][keyChild]).map(value => Number(value)),
          );
          dispatch(
            optionActions.setSelectedRegulations(OPTIONS.KEYS.REGULATIONS_SELECTED, regulations),
          );
          break;
        default:
          break;
      }
    });
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container mobileMode={mobileMode}>
      <DashboardContent mobileMode={mobileMode} />
    </Container>
  );
};
