import React from 'react';

import { Container, Bounce1, Bounce2 } from './Spinner.styles';

export const Spinner = ({ size }) => (
  <Container size={size}>
    <Bounce1 />
    <Bounce2 />
  </Container>
);
