import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const ItemContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
  padding: 28px 0;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 1s ease-in-out;
`;

export const ItemTitle = styled.div`
  width: 100%;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-title4);
  letter-spacing: 0.7px;
  line-height: 1.17;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.regulations.items.title};

  @media ${device.desktopLarge} {
    font-size: var(--font-size-title3);
  }
`;

export const ItemSubtitleContainer = styled.div`
  margin-bottom: 12px;
`;

export const ItemSubtitle = styled.span`
  line-height: 1.23;
  color: ${({ theme }) => theme.regulations.items.date};

  & + & {
    margin-left: 3rem;
  }
`;

export const ItemPreview = styled.div`
  font-size: var(--font-size-caption1);
  line-height: normal;
  color: ${({ theme }) => theme.regulations.items.preview};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.regulations.items.headings};
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: var(--font-weight-bold);
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: var(--font-weight-bold);
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: var(--font-weight-bold);
  }
  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: var(--font-weight-bold);
  }
  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: var(--font-weight-bold);
  }
  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: var(--font-weight-bold);
  }

  strong,
  b {
    font-weight: var(--font-weight-bold);
  }

  em {
    font-style: italic;
  }

  ul li {
    list-style: disc inside;

    ul li {
      list-style: circle inside;
    }
  }

  ol {
    list-style: decimal inside;
  }

  ul ul,
  ol ul,
  ul ol,
  ol ol {
    padding: 0px 10px;
    margin: 0px 10px;
  }

  p {
    margin: 0.3rem 0;
  }
`;

export const ItemBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ open }) => (open ? `space-between` : `flex-start`)};
  margin-top: ${({ open }) => (open ? `35px` : `12px`)};
  width: 100%;
`;

export const ItemButton = styled.div`
  font-weight: var(--font-weight-semibold);
  height: 32px;
  font-size: var(--font-size-caption1);
  line-height: var(--line-height-title3);
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.regulations.items.arrowText};
`;

export const ItemButtonURL = styled.div`
  height: 45px;
  border-radius: 25px;
  width: 233px;
  font-weight: var(--font-weight-semibold);
  line-height: 45px;
  letter-spacing: 0.29px;
  text-align: center;
  color: ${({ theme }) => theme.regulations.items.buttonText};
  background-color: ${({ theme }) => theme.regulations.items.buttonBackground};

  a {
    display: block;
    color: inherit;
    text-decoration: none;
    width: 100%;
  }
`;
