const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

const device = {
  mobile: `(min-width: ${breakpoints.sm}px)`,
  tablet: `(min-width: ${breakpoints.md}px)`,
  desktop: `(min-width: ${breakpoints.lg}px)`,
  desktopLarge: `(min-width: ${breakpoints.xl}px)`,
  desktopExtraLarge: `(min-width: ${breakpoints.xxl}px)`,
};

export default device;
