import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

export const KEYS = {
  SELECTED_COUNTRY: 'selected_country',
  REGULATIONS_SELECTED: 'regulations',
  REGULATIONS_YEAR: 'year',
  REGULATION_LEVEL: 'level',
  REGULATION_CATEGORY: 'category',
};

export const REGULATION_LEVEL = {
  regional: { key: 'regional', label: 'Supranational', order: 0 },
  national: { key: 'national', label: 'National', order: 1 },
  state: { key: 'state', label: 'Subnational', order: 2 },
  municipal: { key: 'municipal', label: 'Local', order: 3 },
  all: { key: 'all', label: 'All Levels of Jurisdiction', order: 4 },
};

export const useAreRegulationsFiltered = () => {
  const currentRegulations = useSelector(
    (state) => state.options[KEYS.REGULATIONS_SELECTED].selected,
  );

  const hasRegulations = useCallback((reg) => reg.length > 0, []);

  return useMemo(() => hasRegulations(currentRegulations), [hasRegulations, currentRegulations]);
};

export const useAreFiltersModified = () => {
  const currentFilters = useSelector((state) => state.options);

  const compareKey = useCallback(
    (filters, key, value) => !filters[key] || filters[key].selected === value,
    [],
  );

  return useMemo(
    () =>
      !compareKey(currentFilters, KEYS.SELECTED_COUNTRY, null) ||
      !compareKey(currentFilters, KEYS.REGULATIONS_SELECTED, []) ||
      !compareKey(currentFilters, KEYS.REGULATIONS_YEAR, null) ||
      !compareKey(currentFilters, KEYS.REGULATION_LEVEL, REGULATION_LEVEL.NATIONAL.key),
    [currentFilters, compareKey],
  );
};
