import React, { useState, useRef, useEffect } from 'react';

import logo from '../../../styles/assets/images/gdpi-logo-v3_-_tara_c_wright.png';

import { Container } from './Header.styles';

const SCROLL_THRESHOLD = 80;

export const Header = () => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const scrollRef = useRef(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > SCROLL_THRESHOLD && scrollRef.current <= SCROLL_THRESHOLD) {
      setScrolled(true);
    } else if (position <= SCROLL_THRESHOLD && scrollRef.current > SCROLL_THRESHOLD) {
      setScrolled(false);
    }
    scrollRef.current = position;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    document.addEventListener('DOMContentLoaded', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container className="subdomain-header-fixed">
      <header
        id="header"
        className={`subdomain-header js-header ${scrolled ? 'header--scroll' : ''}`}
      >
        <div className="subdomain-header__main">
          <div className="subdomain-logo">
            <div className="container">
              <div className="subdomain-logo__inner">
                <div className="subdomain-logo__left">
                  <a
                    href="https://cyber.fsi.stanford.edu/gdpi"
                    className="hidden-on-desktopmediumonly"
                  >
                    <img src={logo} alt="Global Digital Policy Incubator logo" />
                  </a>
                  <a
                    href="https://cyber.fsi.stanford.edu/gdpi"
                    className="hidden-on-desktop-medium"
                  >
                    <img src={logo} alt="Global Digital Policy Incubator logo" />
                  </a>
                </div>
                <div className="subdomain-logo__right">
                  <h3 className="subdomain-logo__content">
                    <p>
                      A program of the{' '}
                      <a href="https://cyber.fsi.stanford.edu">Cyber Policy Center</a>, part of the{' '}
                      <a href="https://fsi.stanford.edu">
                        Freeman Spogli Institute for International Studies
                      </a>
                      .
                    </p>{' '}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`subdomain-nav-search subdomain-nav-search--scroll js-subdomain-nav-search hidden-on-desktopmediumonly ${
              showMenu ? 'show-nav-search' : ''
            }`}
          >
            <span
              className="subdomain-nav-search__btn"
              onClick={() => setShowMenu(value => !value)}
            ></span>
            <div className="subdomain-nav-search__inner container">
              {/* <!-- {# Logo and close btn mobile #}--> */}
              <div className="subdomain-nav-search__logo">
                <span className="subdomain-nav-search__close-btn"></span>
              </div>
              {/* <!-- {# Search #}--> */}
              <div className="subdomain-search">
                <div className="search-icon">
                  <i className="icon-search"></i>
                </div>
                <div className="search-box">
                  <form
                    className="google-cse"
                    action="https://cyber.fsi.stanford.edu/gdpi/"
                    method="post"
                    id="search-block-form"
                    acceptCharset="UTF-8"
                  >
                    <div>
                      <div className="container-inline">
                        <h2 className="element-invisible">Search form</h2>
                        <div className="form-item form-type-textfield form-item-search-block-form">
                          <input
                            title="Enter the terms you wish to search for."
                            className="input-medium search-query feild-search_block_form-textfield form-text"
                            placeholder="Search Global Digital Policy Incubator"
                            data-label=""
                            type="text"
                            id="edit-search-block-form--2"
                            name="search_block_form"
                            defaultValue=""
                            size="15"
                            maxLength="128"
                          />
                        </div>
                        <div className="form-actions form-wrapper" id="edit-actions">
                          <input
                            className="btn btn-search form-submit"
                            type="submit"
                            id="edit-submit"
                            name="op"
                            defaultValue="Search"
                          />
                        </div>
                        <input
                          type="hidden"
                          name="form_build_id"
                          defaultValue="form-7shW9u1WCW4TgOZ0614oVgNANC8anuuxZtvCmimB0oc"
                        />
                        <input
                          type="hidden"
                          name="form_token"
                          defaultValue="XhErSL_FnEEfB1J-NeoYkcNxMTkfe98d0_q7X0AGnaE"
                        />
                        <input type="hidden" name="form_id" defaultValue="search_block_form" />
                      </div>
                    </div>
                  </form>{' '}
                </div>
              </div>
              {/* <!-- {# Menu #}--> */}
              <div className="region region-navigation">
                <div
                  id="block-fsi-custom-rewrites-fsi-new-domain-menu"
                  className="block block-fsi-custom-rewrites contextual-links-region"
                >
                  <div className="content">
                    <div className="subdomain-nav js-expanded-menu">
                      <ul className="menu nav">
                        <li className="first leaf">
                          <a href="https://cyber.fsi.stanford.edu/gdpi">Home</a>
                        </li>
                        <li className="leaf">
                          <a href="https://cyber.fsi.stanford.edu/gdpi/global-digital-policy-incubator-mission">
                            About
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="https://cyber.fsi.stanford.edu/gdpi/gdpi-people">People</a>
                        </li>
                        <li className="last leaf">
                          <a href="https://cyber.fsi.stanford.edu/gdpi/gdpi-whats-new">
                            What&#039;s New?
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`subdomain-nav-search js-subdomain-nav-search ${
              showSideMenu ? 'show-nav-search' : ''
            }`}
          >
            <span
              className="subdomain-nav-search__btn hidden-on-desktop-medium"
              onClick={() => setShowSideMenu(true)}
            ></span>
            <div className="subdomain-nav-search__inner container">
              {/* <!-- {# Logo and close btn mobile #}--> */}
              <div className="subdomain-nav-search__logo">
                <span
                  className="subdomain-nav-search__close-btn"
                  onClick={() => setShowSideMenu(false)}
                ></span>
              </div>
              {/* <!--  {# Search #}--> */}
              <div className="subdomain-search">
                <div className="search-icon" onClick={() => setShowSearchBox(value => !value)}>
                  <i className="icon-search"></i>
                </div>
                {(showSearchBox || showSideMenu) && (
                  <div className="search-box search-box--show">
                    <form
                      className="google-cse"
                      action="https://cyber.fsi.stanford.edu/gdpi/"
                      method="post"
                      id="search-block-form"
                      acceptCharset="UTF-8"
                    >
                      <div>
                        <div className="container-inline">
                          <h2 className="element-invisible">Search form</h2>
                          <div className="form-item form-type-textfield form-item-search-block-form">
                            <input
                              title="Enter the terms you wish to search for."
                              className="input-medium search-query feild-search_block_form-textfield form-text"
                              placeholder="Search Global Digital Policy Incubator"
                              data-label=""
                              type="text"
                              id="edit-search-block-form--2"
                              name="search_block_form"
                              defaultValue=""
                              size="15"
                              maxLength="128"
                            />
                          </div>
                          <div className="form-actions form-wrapper" id="edit-actions">
                            <input
                              className="btn btn-search form-submit"
                              type="submit"
                              id="edit-submit"
                              name="op"
                              defaultValue="Search"
                            />
                          </div>
                          <input
                            type="hidden"
                            name="form_build_id"
                            defaultValue="form-7shW9u1WCW4TgOZ0614oVgNANC8anuuxZtvCmimB0oc"
                          />
                          <input
                            type="hidden"
                            name="form_token"
                            defaultValue="XhErSL_FnEEfB1J-NeoYkcNxMTkfe98d0_q7X0AGnaE"
                          />
                          <input type="hidden" name="form_id" defaultValue="search_block_form" />
                        </div>
                      </div>
                    </form>{' '}
                  </div>
                )}
              </div>
              {/* <!--  {# Menu #}--> */}
              <div className="region region-navigation">
                <div className="region region-navigation">
                  <div
                    id="block-fsi-custom-rewrites-fsi-new-domain-menu"
                    className="block block-fsi-custom-rewrites contextual-links-region"
                  >
                    <div className="content">
                      <div className="subdomain-nav js-expanded-menu">
                        <ul className="menu nav">
                          <li className="first leaf">
                            <a href="https://cyber.fsi.stanford.edu/gdpi">Home</a>
                          </li>
                          <li className="leaf">
                            <a href="https://cyber.fsi.stanford.edu/gdpi/global-digital-policy-incubator-mission">
                              About
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="https://cyber.fsi.stanford.edu/gdpi/gdpi-people">People</a>
                          </li>
                          <li className="last leaf">
                            <a href="https://cyber.fsi.stanford.edu/gdpi/gdpi-whats-new">
                              What&#039;s New?
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- /#header --> */}
    </Container>
  );
};
