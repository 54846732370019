import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Tooltip = styled.span`
  background: rgba(255, 255, 255, 0.95);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.filters.taxonomies.tooltip.color};
  font-size: var(--font-size-caption2);
  letter-spacing: 0.2px;
  line-height: 1;
  margin-left: 0.75rem;
  top: -10px;
  width: fit-content;
  max-width: 260px;
  opacity: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s ease;
  visibility: hidden;
  z-index: 100;

  &::before {
    left: -10px;
    top: 20px;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-right-color: ${({ theme }) => theme.filters.taxonomies.tooltip.arrow};
    border-width: 5px;
    margin-top: -5px;
    z-index: 110;
  }
`;

export const ListTitle = styled.div`
  font-size: var(--font-size-title4);
  line-height: var(--line-height-title4);
  margin-bottom: 18px;
  letter-spacing: 0.63px;
  color: ${({ theme }) => theme.filters.taxonomies.title};
  width: fit-content;

  @media ${device.desktopLarge} {
    font-size: var(--font-size-title3);
    line-height: var(--line-height-title3);
  }

  &[data-tooltip] {
    cursor: help;
  }

  svg {
    vertical-align: middle;
    margin-left: 0.5rem;

    rect {
      transition: fill 0.3s ease;
    }
  }

  &:hover {
    svg rect {
      fill: ${({ theme }) => theme.filters.taxonomies.tooltip.info};
    }

    ${Tooltip} {
      visibility: visible;
      opacity: 1;
    }
  }
`;
