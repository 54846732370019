import { geoPath, geoMercator } from 'd3-geo';
import { CORRECTIONS } from '../../../helpers/mapCorrections';
import { THEME } from 'styles/themes/light';

export const MAP_INITIAL_CENTER = [15, 40];
export const MAP_INITIAL_ZOOM = 0.9;
export const MAP_MIN_ZOOM = 0.9;
export const MAP_MAX_ZOOM = 16;

export const MAP_INITIAL_POSITION = {
  coordinates: MAP_INITIAL_CENTER,
  zoom: MAP_INITIAL_ZOOM,
};

export const SPRING_FROM = { zoom: MAP_INITIAL_ZOOM };
export const SPRING_CONFIG = { mass: 1, tension: 100, friction: 20 };
export const PROJECTION_CONFIG = {};

const projection = geoMercator();

export const MAP_FILL_COLOR = {
  NO: THEME.map.fill.no,
  YES: THEME.map.fill.yes,
  YES_INNER: THEME.map.fill.yesInner,
};

export const filterZoom = event => (event?.type === 'wheel' ? false : true);

export const getSelectedGeoAndPosition = (geographiesList, selected) => {
  if (!selected) return MAP_INITIAL_POSITION;

  const geo = geographiesList.find(geo => geo.id === selected);
  if (!geo) return MAP_INITIAL_POSITION;

  let center, zoom;
  if (selected in CORRECTIONS) {
    ({ center, zoom } = CORRECTIONS[selected]);
  } else {
    const path = geoPath().projection(projection);
    center = projection.invert(path.centroid(geo));
    const b = path.bounds(geo);
    zoom = 0.9 / Math.max((b[1][0] - b[0][0]) / 800, (b[1][1] - b[0][1]) / 600);
  }

  return {
    geo,
    coordinates: center,
    zoom,
  };
};
