import { useState, useLayoutEffect } from 'react';

export const useMediaQuery = ({ min, max }) => {
  const [matches, setMatches] = useState(false);

  useLayoutEffect(() => {
    const matchesQuery = () => {
      const width = document.body.getBoundingClientRect()?.width ?? 0;
      setMatches((!min || min <= width) && (!max || max >= width));
    };

    matchesQuery();

    window.addEventListener('resize', matchesQuery);
    return () => {
      window.removeEventListener('resize', matchesQuery);
    };
  }, [min, max]);

  return matches;
};
