import client from '../api/httpClient';

class StatesController {
  constructor() {
    this.baseUrl = '/states';
  }

  fetchStates = async (country, types) => {
    if (country === 'world') return [];
    const params = {
      location: country,
      type:
        types?.length > 0
          ? {
              0: types[0],
              1: types[1],
              2: types[2],
              3: types[3],
            }
          : null,
    };
    try {
      const { data } = await client.get(this.baseUrl, { params });
      return data;
    } catch {
      return [];
    }
  };
}

export default new StatesController();
