import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './utils/serviceWorker';
import { App } from './pages/App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
