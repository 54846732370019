import styled, { css } from 'styled-components/macro';
import device from 'styles/common/breakpoints';
import { Link } from 'react-scroll';

export const FilterContainer = styled.div`
  background: #fff;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  height: 60px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 64px;
  width: 100%;
  z-index: 100;

  @media (min-width: 1368px) {
    top: 68px;
  }
`;

export const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr 1fr;
`;

export const FilterNavigation = styled.nav`
  position: relative;
  display: flex;
  align-items: center;

  & + & {
    &::before {
      content: '';
      height: 100%;
      border-right: 1px solid #d8d8d8;
      position: absolute;
      left: -2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:last-child {
    justify-content: space-around;
  }
`;

const baseFilterStyles = css`
  color: ${({ theme }) => theme.filters.item};
  font-size: var(--font-size-title5);
  letter-spacing: 0.38px;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 0.3s ease;
  text-transform: initial;

  @media ${device.desktopLarge} {
    font-size: var(--font-size-title4);
  }

  & + & {
    margin-left: 1rem;

    @media ${device.desktopLarge} {
      margin-left: 2.5rem;
    }
  }

  &::before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(140, 22, 21, 0);
    border-top-color: ${({ theme }) => theme.filters.arrow};
    border-width: 10px;
    margin-left: -10px;
    transition: opacity 0.3s ease;
    transform: translateY(100%);
  }

  &::after {
    background: ${({ theme }) => theme.filters.arrow};
    content: '';
    height: 3px;
    bottom: -100%;
    margin-bottom: -5px;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    transition: opacity 0.3s ease;

    @media ${device.desktopLarge} {
      margin-bottom: -3px;
    }
  }

  &.active,
  &:hover,
  &:active {
    background: none;
    border: none;
    color: ${({ theme }) => theme.filters.item};
    font-weight: var(--font-weight-semibold);
    text-decoration: none;

    ::before,
    ::after {
      opacity: 1;
    }
  }
`;

export const FilterItem = styled.button`
  ${baseFilterStyles}
  background: none;
  border: none;
  line-height: 1;
  min-width: auto;

  &.active,
  &:hover,
  &:active,
  &:focus {
    background: none;
    border: none;
  }
`;

export const FilterLink = styled(Link)`
  ${baseFilterStyles}
`;
