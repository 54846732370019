import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as optionActions from 'actions/optionActions';
import * as OPTIONS from 'helpers/options';

import {
  ControlsContainer,
  LegendContainer,
  Legend,
  LegendColor,
  LegendText,
  LegendFilter,
} from './MapControls.styles';

import { ReactComponent as CrossFill } from 'styles/assets/images/cross-fill.svg';

export const MapControls = ({ filters }) => {
  const dispatch = useDispatch();
  const selected = useSelector(
    state => state.options?.[OPTIONS.KEYS.REGULATIONS_SELECTED]?.selected,
  );
  const filtersSelected = useMemo(() => {
    if (selected.length > 0) {
      let flag = false;
      selected.forEach((item, col) => {
        if (col > 0) {
          if (item.length > 0) {
            flag = true;
          }
        }
      });
      return flag;
    }
    return false;
  }, [selected]);

  const removeSelected = useCallback(
    (option, column, selected) => {
      dispatch(optionActions.removeSelectedOption(option, column, selected));
    },
    [dispatch],
  );

  return (
    <ControlsContainer>
      <LegendContainer>
        <Legend>
          <LegendColor />
          <LegendText>Present</LegendText>
        </Legend>
        <Legend>
          <LegendColor isNegativeLabel />
          <LegendText>Absent</LegendText>
        </Legend>
      </LegendContainer>
      {filtersSelected &&
        selected.map((list, column) => {
          if (column > 0) {
            let parent = Object.values(filters[selected[0][0]].children).find(
              el => el.order === column,
            );
            if (parent) {
              return list.map(item => {
                let found = Object.values(parent.children).find(el => el.id === item);
                if (found) {
                  return (
                    <LegendFilter key={item}>
                      {found.name}
                      <CrossFill
                        onClick={() =>
                          removeSelected(OPTIONS.KEYS.REGULATIONS_SELECTED, column, item)
                        }
                      />
                    </LegendFilter>
                  );
                }
                return null;
              });
            }
            return null;
          }
          return null;
        })}
    </ControlsContainer>
  );
};
