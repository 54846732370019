import React from 'react';
import { Container, Controls } from './MapZoom.styles';
import { ReactComponent as AddIcon } from '../../styles/assets/images/add.svg';
import { ReactComponent as RemoveIcon } from '../../styles/assets/images/remove.svg';

export const MapZoom = ({ handleZoomIn, handleZoomOut }) => (
  <Container>
    <Controls>
      <button onClick={handleZoomIn}>
        <AddIcon />
      </button>
      <button onClick={handleZoomOut}>
        <RemoveIcon />
      </button>
    </Controls>
  </Container>
);
