export const ACTION_TYPES = {
  UPDATE_SELECTED_OPTION: 'UPDATE_SELECTED_OPTION',
  RESET_FILTERS: 'RESET_FILTERS',
  ADD_SELECTED_OPTION: 'ADD_SELECTED_OPTION',
  REMOVE_SELECTED_OPTION: 'REMOVE_SELECTED_OPTION',
  ADD_OPTION_ARRAY: 'ADD_OPTION_ARRAY',
  CLEAR_OPTION_ARRAY: 'CLEAR_OPTION_ARRAY',
  SET_SELECTED_REGULATIONS: 'SET_SELECTED_REGULATIONS',
};

export const updateSelectedOption = (option, selectedKey) => ({
  type: ACTION_TYPES.UPDATE_SELECTED_OPTION,
  option,
  selectedKey,
});

export const addSelectedOption = (option, column, selectedKey) => ({
  type: ACTION_TYPES.ADD_SELECTED_OPTION,
  option,
  column,
  selectedKey,
});

export const removeSelectedOption = (option, column, selectedKey) => ({
  type: ACTION_TYPES.REMOVE_SELECTED_OPTION,
  option,
  column,
  selectedKey,
});

export const addOptionArray = (option, selectedKey) => ({
  type: ACTION_TYPES.ADD_OPTION_ARRAY,
  option,
  selectedKey,
});

export const clearOptionArray = (option) => ({
  type: ACTION_TYPES.CLEAR_OPTION_ARRAY,
  option,
});

export const resetFilters = () => ({
  type: ACTION_TYPES.RESET_FILTERS,
});

export const setSelectedRegulations = (option, selected) => ({
  type: ACTION_TYPES.SET_SELECTED_REGULATIONS,
  option,
  selected,
});
