import React from 'react';
import { useSelector } from 'react-redux';

import * as OPTIONS from '../../helpers/options';
import { useCustomSelect } from 'hooks/';
import {
  Container,
  ContainerText,
  // ArrowContainer,
  ItemsContainer,
  Item,
  ItemsContainerSpacer,
  Clear,
  Flag,
} from './CountrySelect.styles';
// import { ReactComponent as Arrow } from '../../styles/assets/images/arrow.svg';
import { ReactComponent as Checkmark } from '../../styles/assets/images/check.svg';

const PLACEHOLDER = 'Select a location';

export const CountrySelect = ({ items, onSelect, flags }) => {
  const selected = useSelector(state => state.options?.[OPTIONS.KEYS.SELECTED_COUNTRY]?.selected);
  const [open, selectedItem, disabled, handleOpen] = useCustomSelect({
    selected,
    items,
  });

  return (
    <Container onClick={handleOpen} open={open} disabled={disabled}>
      <Flag>
        <img src={selected ? flags[selected] : flags['world']} alt="Flag" />
      </Flag>
      <ContainerText disabled={disabled}>
        {selectedItem ? selectedItem.label : PLACEHOLDER}
      </ContainerText>
      {/* {!disabled && (
        <ArrowContainer open={open}>
          <Arrow />
        </ArrowContainer>
      )} */}
      {open && (
        <ItemsContainerSpacer>
          <ItemsContainer>
            <Clear onClick={() => onSelect('world')}>Clear all</Clear>
            {items.map(item => (
              <Item
                key={item.key}
                selected={item.key === selected}
                onClick={() => onSelect(item.key)}
              >
                {item.label}
                {item.key === selected && <Checkmark />}
              </Item>
            ))}
          </ItemsContainer>
        </ItemsContainerSpacer>
      )}
    </Container>
  );
};
