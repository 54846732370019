import React, { useCallback, useMemo } from 'react';

import { GeographyStyled } from './Maps.styles';

export const GeographyItem = ({
  geography,
  onClick,
  onMouseEnter,
  onMouseLeave,
  getGeographyColor,
}) => {
  const handleMapClick = useCallback(() => {
    onClick(geography);
  }, [onClick, geography]);

  const handleMouseEnter = useCallback(() => {
    onMouseEnter(geography);
  }, [onMouseEnter, geography]);

  const handleMouseLeave = useCallback(() => {
    onMouseLeave(geography);
  }, [onMouseLeave, geography]);

  const fillColor = useMemo(() => getGeographyColor(geography), [getGeographyColor, geography]);

  return (
    <GeographyStyled
      geography={geography}
      onClick={handleMapClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      geocolor={fillColor}
    />
  );
};
