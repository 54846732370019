import * as colors from '../common/colors';

export const THEME = {
  mainContent: {
    background: colors.WHITE,
  },
  filters: {
    background: colors.BLACK_2,
    backgroundLight: colors.WHITE,
    title: colors.MIDNIGHT,
    titleLight: colors.WHITE,
    subtitle: colors.SHUTTLE_GREY,
    item: colors.MIDNIGHT2,
    arrow: colors.RED,
    link: colors.RED_2,
    description: colors.WHITE,
    clear: colors.RED,
    share: {
      background: colors.RED,
      text: colors.WHITE,
      icon: colors.WHITE,
    },
    headerCard: {
      background: colors.WHITE_TRANSPARENT,
      border: colors.PATTENS_BLUE,
      title: colors.RED,
      text: colors.MIDNIGHT,
      message: {
        background: colors.RED,
        text: colors.WHITE,
      },
    },
    taxonomies: {
      title: colors.MIDNIGHT2,
      backgroundSelected: colors.RED,
      label: colors.MIDNIGHT2,
      labelFirstSelected: colors.WHITE,
      labelRestSelected: colors.MIDNIGHT2,
      labelDisabled: colors.SHUTTLE_GREY,
      borderFirst: colors.PATTENS_BLUE_2,
      tooltip: {
        arrow: colors.WHITE,
        color: colors.MIDNIGHT2,
        info: colors.RED,
      },
    },
    button: {
      background: colors.RED,
      hover: colors.PATTENS_BLUE_3,
      color: colors.WHITE,
    },
  },
  map: {
    fill: {
      no: colors.GLACIER,
      yes: colors.MIDNIGHT,
      yesInner: colors.TURQUOISE_BLUE_LIGHT,
      hover: colors.SAIL,
      active: colors.TURQUOISE_BLUE_LIGHT,
    },
    container: {
      background: colors.WHITE,
    },
    controls: {
      button: {
        color: colors.RED,
        hover: colors.PATTENS_BLUE_3,
      },
      border: colors.CELLO,
    },
    legend: {
      text: colors.MIDNIGHT2,
      colorYes: colors.MIDNIGHT,
      colorNo: colors.GLACIER,
      colorState: colors.TURQUOISE_BLUE_LIGHT,
    },
    legendFilter: {
      text: colors.MIDNIGHT2,
      background: colors.PATTENS_BLUE_3,
      buttonColor: colors.MIDNIGHT,
    },
    select: {
      background: colors.BLACK_2,
      backgroundOpen: colors.BLACK_2,
      disabledBackground: colors.POLO_BLUE,
      itemColor: colors.WHITE,
      itemSelectedColor: colors.SAIL,
      selectedItemDisabledColor: colors.TUNDORA,
      highlightColor: colors.SAIL,
      arrowColor: colors.SAIL,
      border: colors.CELLO,
      scroll: {
        thumb: colors.SAIL,
        line: colors.ASTRONAUT_BLUE,
        background: colors.BLACK_2,
      },
      clear: colors.RED_2,
    },
    spinner: {
      color: colors.TURQUOISE_BLUE,
    },
    filter: {
      color: colors.MIDNIGHT,
      selectedColor: colors.RED,
      slider: colors.RED,
    },
    popover: {
      background: colors.ALICE_BLUE,
      color: colors.RED,
      border: colors.ALABASTER,
      title: colors.MIDNIGHT,
      buttonColor: colors.RED,
    },
  },
  regulations: {
    countrySelect: {
      color: colors.NERO,
    },
    regulationSelect: {
      color: colors.MIDNIGHT,
    },
    select: {
      backgroundOpen: colors.WHITE,
      disabledBackground: colors.POLO_BLUE,
      itemColor: colors.NERO,
      itemListColor: colors.MIDNIGHT,
      itemSelectedColor: colors.RED,
      selectedItemDisabledColor: colors.TUNDORA,
      highlightColor: colors.RED_2,
      arrowColor: colors.RED,
      border: colors.SNOW_WHITE,
      scroll: {
        thumb: colors.SAIL,
        line: colors.PATTENS_BLUE,
        background: colors.WHITE,
      },
      clear: colors.RED_2,
      checkmark: colors.SAIL,
    },
    descriptionColor: colors.SHUTTLE_GREY,
    items: {
      title: colors.NERO_2,
      date: colors.MIDNIGHT,
      preview: colors.SHUTTLE_GREY,
      arrowText: colors.TUNDORA,
      arrowColor: colors.RED,
      buttonText: colors.WHITE,
      buttonBackground: colors.RED,
      headings: colors.MIDNIGHT,
    },
    filter: {
      title: colors.MIDNIGHT,
      items: colors.SHUTTLE_GREY,
      label: colors.MIDNIGHT2,
      labelDisabled: colors.NOBEL,
    },
    pagination: {
      color: colors.MIDNIGHT2,
      hoverBackground: colors.PATTENS_BLUE_3,
      activeBackground: colors.GLACIER,
    },
  },
  footer: {
    text: colors.WHITE,
    socialBackground: colors.WHITE,
  },
};
