import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 9999;
  margin-bottom: 116px;

  @media (min-width: 1368px) {
    margin-bottom: calc(57px + 80px);
  }

  li {
    line-height: 1.4;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    font-family: 'FontAwesome', 'SourceSansPro-Regular', Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    display: inline;
    width: auto;
    height: auto;
    line-height: normal;
    vertical-align: baseline;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    margin-top: 0;
  }

  .sticky-nav,
  .js-expanded-menu {
    font: normal 1em / 1.55em 'ss', Arial, sans-serif;
  }
`;
