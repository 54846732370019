import styled from 'styled-components/macro';

export const BaseButton = styled.button`
  background-color: unset;
  border: none;
  min-width: unset;
  display: initial;
  text-transform: initial;
  padding: 0;

  :hover,
  :focus,
  :active {
    background-color: unset;
    border: none;
    color: initial;
  }
`;
