import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import StatesController from '../controllers/StatesController';
import * as OPTIONS from '../helpers/options';

export const useDivisions = () => {
  const [divisions, setDivisions] = useState(null);
  const [loadedDivisions, setLoadedDivisions] = useState(false);
  const country = useSelector((state) => state.options?.[OPTIONS.KEYS.SELECTED_COUNTRY]?.selected);
  const types = useSelector(
    (state) => state.options?.[OPTIONS.KEYS.REGULATIONS_SELECTED]?.selected,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (country) {
        setLoadedDivisions(false);
        const result = await StatesController.fetchStates(country, types);
        setDivisions(result);
        setLoadedDivisions(true);
      }
    };
    fetchData();
  }, [country, types]);

  return [divisions, loadedDivisions];
};
