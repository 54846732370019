import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';

export const Container = styled.div`
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.mainContent.background};
  z-index: 1;

  @media ${device.desktopLarge} {
    padding: 0;
  }

  @media (min-width: 1024px) {
    max-width: 73.75rem;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    max-width: 60rem;
  }
`;

export const RegulationsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SpinnerContainer = styled.div`
  height: ${({ mini }) => (mini ? '300px' : '600px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Description = styled.div`
  font-size: var(--font-size-caption1);
  line-height: 1.6;
  letter-spacing: 0.17px;
  margin-top: 11px;
  color: ${({ theme }) => theme.regulations.descriptionColor};
`;

export const RegulationsContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
`;

export const YearFilterContainer = styled.div`
  width: 57px;
  margin-right: 30px;
`;

export const NoRegulations = styled.div`
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-title4);
  line-height: 1.78;
  letter-spacing: 0.29px;
  color: ${({ theme }) => theme.regulations.filter.title};
  width: 100%;
  text-align: center;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
`;

export const ListContainer = styled.div`
  width: calc(100% - 325px);
  display: flex;
  flex-direction: column;
`;

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span`
  font-size: var(--font-size-caption1);
  line-height: var(--line-height-caption1);
  letter-spacing: 1.75px;
  text-transform: uppercase;
  padding: 8px 0;
  color: ${({ theme }) => theme.regulations.pagination.color};
  display: ${({ enabled }) => (enabled ? 'inline' : 'none')};
`;
