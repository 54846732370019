import styled from 'styled-components/macro';
import device from 'styles/common/breakpoints';
import { Geography } from 'react-simple-maps';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.map.container.background};
  padding: 0 20px;

  @media ${device.desktopLarge} {
    padding: 0;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  max-width: 1040px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    max-width: 73.75rem;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    max-width: 60rem;
  }
`;

export const MapContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;

  & > .rsm-svg {
    margin-top: 10px;
    /* height: 475px; */
    width: 100%;
    height: 100%;
  }
`;

export const MapOverlay = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  width: calc(100% - 281px);
  height: calc(100% - 10px);
  pointer-events: none;
  z-index: 10;
`;

export const SpinnerContainer = styled.div`
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GeographyStyled = styled(Geography)`
  fill: ${({ geocolor }) => geocolor};
  outline: none;

  :hover {
    fill: ${({ theme }) => theme.map.fill.hover};
  }
  :active {
    fill: ${({ theme }) => theme.map.fill.active};
  }
`;
